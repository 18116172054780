import axios from "axios";
import React, { useState, useEffect } from "react";
import validator from "../../helper/validator";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config/config.json";
import { Calendar } from "primereact/calendar";
import moment from "moment";
const host = config.APP_URL;

const Holiday = (props) => {
	const navigate = useNavigate();
	const [newHoliday, setHoliday] = useState({});
	const [getHolidayData, setGetHoliday] = useState([]);
	const [holidayDate, setHolidayDate] = useState({});
	const [resp, setResp] = useState(false);
	const d = new Date();
	const bookingDate = `${moment(holidayDate.date).format(
		"YYYY-MM-DD"
	)}T00:00:00.000+00:00`;

	const getHolidays = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/admin/holiday`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				props.showLoader(false);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
				setGetHoliday(response.data.data);
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const handleChange = (e) => {
		setHoliday({ ...newHoliday, [e.target.name]: e.target.value });
	};

	const deleteHoliday = async (id) => {
		props.showLoader(true);
		await axios
			.delete(`${host}/api/admin/holiday/${id}`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				console.log("response", response);
				props.showLoader(false);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
				props.showAlert(true, response.data.message, "success");
				setResp(true);
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const result = validator.validateHoliday.validate(newHoliday);
		const { error, value } = result;
		if (!error) {
			props.showLoader(true);
			await axios
				.post(`${host}/api/admin/holiday`, value, {
					headers: {
						"Content-Type": "application/json",
						"x-access-token": localStorage.getItem("token"),
					},
				})
				.then((response) => {
					props.showLoader(false);
					if (response.data.token) {
						localStorage.setItem("token", response.data.token);
					}
					props.showAlert(true, response.data.message, "success");
					setResp(true);
					document.getElementById("holidayForm").reset();
					setHoliday({});
				})
				.catch((err) => {
					props.showLoader(false);
					props.showAlert(true, err.response.data.error, "danger");
					if (err.response.status === 401) {
						localStorage.clear();
						navigate("/login");
					}
				});
		} else {
			props.showAlert(true, error.details[0].message, "danger");
		}
	};

	useEffect(() => {
		getHolidays();
		setHoliday({ ...newHoliday, date: bookingDate });
		setResp(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resp]);
	return (
		<>
			<main id="main" className="main">
				<div className="pagetitle">
					<div className="d-flex justify-content-between">
						<div>
							<h1>Holiday</h1>
							<nav>
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/dashboard">Home</Link>
									</li>
									<li className="breadcrumb-item active">Holiday</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>

				<div className="col-md-12">
					<div className="row">
						<div className="col-md-6">
							<div className="card">
								<div className="card-body">
									<h5 className="card-title">Add New Holiday</h5>
									<hr />
									<form className="row g-3" id="holidayForm">
										<div className="col-md-6">
											<label htmlFor="inputEmail5" className="form-label">
												Holiday Date
											</label>
											<Calendar
												style={{ height: "35px", padding: "0px" }}
												disabledDays="[4,5]"
												value={holidayDate.date}
												placeholder="Holiday Date"
												dateFormat="dd/mm/yy"
												onChange={(e) => {
													setHolidayDate({ ...holidayDate, date: e.value });
												}}
												className="form-control"
												minDate={d}
											/>
										</div>
										<div className="col-md-6">
											<label htmlFor="inputZip" className="form-label">
												Status
											</label>
											<select
												className="form-select"
												name="status"
												onChange={handleChange}>
												<option value="">Select Status</option>
												<option value="Active">Active</option>
												<option value="Inactive">Inactive</option>
											</select>
										</div>
										<div className="col-md-12">
											<label htmlFor="inputCity" className="form-label">
												Title
											</label>
											<input
												type="text"
												className="form-control"
												name="title"
												maxLength={25}
												onChange={handleChange}
												placeholder="Holiday Title"
											/>
										</div>
										<div className="text-center">
											<button
												type="button"
												className="btn btn-outline-success m-1"
												onClick={handleSubmit}>
												Add Holiday
											</button>
											<button
												type="reset"
												className="btn btn-outline-danger m-1">
												Reset
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>

						<div className="col-md-6">
							<div className="card">
								<div className="card-body">
									<h5 className="card-title">Holiday List</h5>
									{getHolidayData.length > 0 ? (
										<div className="table-responsive">
											<table className="table table-hover">
												<thead>
													<tr>
														<th scope="col">Sr. No.</th>
														<th scope="col">Title</th>
														<th scope="col">Holiday Date</th>
														<th scope="col">Status</th>
														<th scope="col">Added On</th>
														<th scope="col">Delete</th>
													</tr>
												</thead>
												<tbody>
													{getHolidayData.map((element, index) => {
														return (
															<tr key={index}>
																<th>{index + 1}</th>
																<td>{element.holidayTitle}</td>
																<td>
																	{moment(element.holidayDate).format(
																		"DD-MMM-YYYY"
																	)}
																</td>
																<td>{element.holidayStatus}</td>
																<td>
																	{moment(element.addedDate).format(
																		"DD-MMM-YYYY"
																	)}
																</td>
																<td>
																	<button
																		onClick={() => {
																			deleteHoliday(element._id);
																		}}
																		className="btn btn-danger">
																		<span className="bi bi-trash"></span>
																	</button>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									) : (
										<div
											className="col-md-12 col-lg-12 d-flex justify-content-center align-items-center"
											style={{ height: "200px" }}>
											<h4>No Data Found</h4>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>

			{/* <!-- Vendor JS Files --> */}
			<script src="assets/vendor/apexcharts/apexcharts.min.js"></script>
			<script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
			<script src="assets/vendor/chart.js/chart.umd.js"></script>
			<script src="assets/vendor/echarts/echarts.min.js"></script>
			<script src="assets/vendor/quill/quill.min.js"></script>
			<script src="assets/vendor/simple-datatables/simple-datatables.js"></script>
			<script src="assets/vendor/tinymce/tinymce.min.js"></script>
			<script src="assets/vendor/php-email-form/validate.js"></script>

			{/* <!-- Template Main JS File --> */}
			<script src="assets/js/main.js"></script>
		</>
	);
};

export default Holiday;
