import Joi from "joi";

const validateUser = Joi.object({
	country_code: Joi.string().required().label("Country Code"),
	id: Joi.string()
		.required()
		.min(5)
		.max(15)
		.pattern(new RegExp(/^[0-9]*$/))
		.trim()
		.exist()
		.label("Mobile Number")
		.messages({
			"string.pattern.base": `Mobile Number is not in correct format`,
		}),
});

const verifyOTP = Joi.object({
	country_code: Joi.string().required().label("Country Code"),
	id: Joi.string()
		.required()
		.min(5)
		.max(15)
		.trim()
		.exist()
		.label("Mobile Number"),

	otp: Joi.string()
		.required()
		.length(6)
		.pattern(new RegExp(/^[0-9]*$/))
		.trim()
		.exist()
		.label("Verification Code")
		.messages({
			"string.pattern.base": `Verification Code is not in correct format`,
		}),
});

const validateHoliday = Joi.object({
	title: Joi.string()
		.max(25)
		.required()
		.label("Holiday Title")
		.pattern(new RegExp(/^[\sa-zA-Z0-9.,/_-]*$/))
		.messages({
			"string.pattern.base": `Holiday Title is not in correct format`,
		}),
	status: Joi.string().required().label("Status"),
	date: Joi.string().required().label("Date"),
});

const validateService = Joi.object({
	title: Joi.string()
		.trim()
		.max(25)
		.pattern(new RegExp(/^[\sa-zA-Z0-9.,/_-]*$/))
		.required()
		.label("Service Name")
		.messages({
			"string.pattern.base": `Service Name is not in correct format`,
		}),
	status: Joi.string().trim().required().label("Status"),
	price: Joi.number().min(0).required().label("Price"),
	maxSlot: Joi.number().min(1).required().label("Maximum Slot"),
});

const validateAppointment = Joi.object({
	service: Joi.string().trim().required().label("Service"),
	date: Joi.date().required().label("Booking Date"),
	time: Joi.string().trim().required().label("Booking Time"),
	name: Joi.string().trim().required().label("Name"),
	gender: Joi.string().trim().required().label("Gender"),
	country_code: Joi.string().required().label("Country Code"),
	mobile: Joi.string()
		.trim()
		.required()
		.pattern(new RegExp(/^[0-9]*$/))
		.label("Mobile")
		.messages({
			"string.pattern.base":
				"Enter Mobile Number with country code ex +91 123xxxx33",
		}),
	address: Joi.string().trim().required().label("Address"),
	aadhaar: Joi.number().required().label("Aadhaar Number"),
	file: Joi.optional(),
});

const validateNotice = Joi.object({
	type: Joi.string().required().trim().label("Type"),
	status: Joi.string().required().trim().label("Status"),
	titleHindi: Joi.string().max(100).trim().required().label("Title Hindi"),
	titleEnglish: Joi.string().max(100).trim().required().label("Title English"),
});

const validateNewUser = Joi.object({
	type: Joi.string().trim().required().label("User Type"),
	status: Joi.string().trim().required().label("Status"),
	name: Joi.string().trim().max(50).required().label("Name"),
	gender: Joi.string().trim().required().label("Gender"),
	email: Joi.string().trim().required().label("Email"),
	mobile: Joi.string()
		.trim()
		.required()
		.pattern(/^\+\d{1,3}\d*$/)
		.label("Mobile Number")
		.messages({
			"string.pattern.base":
				"Enter Mobile Number with country code ex +91 123xxxx33",
		}),
	aadhaar: Joi.string().trim().max(12).required().label("Aadhaar Number"),
	address: Joi.string().trim().max(100).required().label("Address"),
});

const validateProfile = Joi.object({
	country_code: Joi.string().required().label("Country Code"),
});

const validateUpdateProfile = Joi.object({
	name: Joi.string().trim().required().label("Name"),
	email: Joi.string().trim().required().label("Email"),
	mobile: Joi.string().trim().required().label("Mobile"),
	aadhaar: Joi.string().trim().length(12).required().label("Aadhaar"),
	address: Joi.string().trim().required().label("Address"),
});

const validateLogin = Joi.object({
	id: Joi.string().label("Email"),
	password: Joi.string().label("Password"),
});

const validateSendOTP = Joi.object({
	country: Joi.string().trim().required().label("Country"),
	gender: Joi.string().trim().required().label("Gender"),
	name: Joi.string().trim().max(50).required().label("Name"),
	mobile: Joi.string().trim().required().length(10).label("Mobile"),
	address: Joi.string().trim().max(100).required().label("Address"),
	email: Joi.string().trim().label("Email"),
	password: Joi.string().trim().min(6).max(12).required().label("Password"),
	confirmPassword: Joi.string()
		.trim()
		.min(6)
		.max(12)
		.required()
		.label("Confirm Password"),
});

const validateSignup = Joi.object({
	otp: Joi.string().trim().length(6).required().label("Verification Code"),
	name: Joi.string().trim().max(50).required().label("Name"),
	email: Joi.string().trim().max(50).label("Email"),
	mobile: Joi.string().trim().length(10).required().label("Mobile"),
	gender: Joi.string().trim().required().label("Gender"),
	address: Joi.string().trim().max(100).required().label("Address"),
	password: Joi.string().trim().min(6).max(12).required().label("Password"),
	country: Joi.string().trim().required().label("Country"),
	confirmPassword: Joi.string()
		.trim()
		.min(6)
		.max(12)
		.required()
		.label("Confirm Password"),
});

const validateResetOtp = Joi.object({
	country: Joi.string().trim().required().label("Country"),
	mobile: Joi.string().trim().required().max(10).label("Mobile"),
	email: Joi.string().trim().required().max(50).label("Email"),
});

const validateResetPassword = Joi.object({
	otp: Joi.string().trim().length(6).required().label("Verification Code"),
	mobile: Joi.string().trim().required().max(10).label("Mobile"),
	email: Joi.string().trim().required().max(50).label("Email"),
	password: Joi.string().trim().min(6).max(12).required().label("Password"),
});

const validator = {
	validateUser,
	verifyOTP,
	validateNewUser,
	validateHoliday,
	validateService,
	validateAppointment,
	validateNotice,
	validateProfile,
	validateUpdateProfile,
	validateLogin,
	validateSendOTP,
	validateSignup,
	validateResetOtp,
	validateResetPassword,
};

export default validator;
