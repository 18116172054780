import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import config from "../../config/config.json";
import moment from "moment";
const host = config.APP_URL;

const CheckAvailibility = (props) => {
	const navigate = useNavigate();
	const [availablilityData, setAvailablilityData] = useState();
	const [service, setService] = useState([]);
	const [data, setData] = useState({});
	const getService = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/utility/service`, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				props.showLoader(false);
				setService(response.data.data);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const getAvalibility = async (e) => {
		e.preventDefault();
		props.showLoader(true);
		await axios
			.get(
				`${host}/api/utility/availablility?service=${
					data.service
				}&date=${moment(data.date).format("YYYY-MM-DD")}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => {
				props.showLoader(false);
				setAvailablilityData(response.data.data);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		getService();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const d = new Date();
	d.setDate(d.getDate() + 1);

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				width: "100vw",
			}}>
			<div className="card" style={{ width: "50rem", height: "30rem" }}>
				<div className="card-body">
					<h5 className="card-title">Check Availability</h5>
					<p className="card-text">
						You Can Check Only Available Slot Here. If You want to Book
						Appointment you have to Login first then you can book your
						appointment
					</p>

					<p className="card-text">
						आप यहाँ केवल उपलब्ध स्लॉट देख सकते हैं। यदि आप अपॉइंटमेंट बुक करना
						चाहते हैं, तो सबसे पहले लॉगिन करें, फिर आप अपना अपॉइंटमेंट बुक कर
						सकते हैं।
					</p>

					<div className="container">
						<div className="row">
							<form>
								<div className="col-sm-12">
									<div className="row">
										<div className="col-md-6">
											<label htmlFor="inputState" className="form-label">
												Service
											</label>
											<select
												className="form-select"
												onChange={handleChange}
												required={true}
												name="service">
												<option value={null}>Select Service</option>
												{service.map((element, index) => {
													return (
														<option value={element.title} key={index}>
															{element.title} Patient
														</option>
													);
												})}
											</select>
										</div>
										<div className="col-md-6">
											<label htmlFor="inputEmail5" className="form-label">
												Date
											</label>
											<br />
											<Calendar
												style={{ height: "35px", padding: "0px" }}
												disabledDays="[4,5]"
												value={setData.date}
												placeholder="Select Date"
												dateFormat="mm/dd/yy"
												required
												onChange={(e) => {
													setData({
														...data,
														date: e.value,
													});
												}}
												className="form-control"
												minDate={d}
											/>
										</div>
										<div className="d-flex justify-content-between mt-5">
											<button
												type="submit"
												onClick={() => {
													navigate("/");
												}}
												className="btn btn-primary btncolor px-3">
												<i className="bi bi-arrow-left-circle"></i>&nbsp; Back
											</button>
											<button
												className="btn btn-primary btncolor px-3"
												onClick={getAvalibility}
												disabled={!data.service && !data.date}
												type="button">
												Check Avalibility
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>

					{availablilityData ? (
						<div className="container d-flex align-items-center justify-content-center mt-2">
							<ul>
								<li>Amount: {availablilityData.amount}</li>
								<li>Available Slot: {availablilityData.availableSlot}</li>
								<li>Total Slot: {availablilityData.totalSlot}</li>
							</ul>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default CheckAvailibility;
