import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import Logo from "../assets/img/sgc_logo.jpeg";
import config from "../../src/config/config.json";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { MENU_OPEN } from "../store/actions";
const host = config.APP_URL;

const Layout = (props) => {
	const customize = useSelector((store) => store.customization);
	const [sideBar, setSideBar] = useState(true);
	const dispatch = useDispatch();

	if (customize.opened) {
		document.body.className = "toggle-sidebar";
	} else {
		document.body.className = null;
	}

	const navigate = useNavigate();
	const [user, setUser] = useState({});

	const userRole = localStorage.getItem("role");

	const handleLogout = async () => {
		await axios
			.get(`${host}/api/auth/logout`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				localStorage.clear();
				navigate("/");
			})
			.catch((err) => {
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	useEffect(() => {
		setUser({ ...user, name: localStorage.getItem("name") });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleNavbar = () => {
		if (window.screen.width < 1200) {
			sideBar === true ? setSideBar(false) : setSideBar(true);
			dispatch({ type: MENU_OPEN, sideBar });
		}
	};

	return (
		<>
			<header
				id="header1"
				className="header fixed-top d-flex align-items-center">
				<div className="d-flex align-items-center justify-content-between">
					<Link
						to={userRole === "User" ? "/appointment" : "/dashboard"}
						className="logo d-flex align-items-center">
						<img src={Logo} alt="" />
						<span className="d-none d-lg-block">
							Sant Gitanjali Chikitsalaya
						</span>
					</Link>
					<i
						className="bi bi-list toggle-sidebar-btn"
						onClick={() => {
							sideBar === true ? setSideBar(false) : setSideBar(true);
							dispatch({ type: MENU_OPEN, sideBar });
						}}></i>
				</div>
				{/* <!-- End Logo --> */}
				<nav className="header-nav ms-auto">
					<ul className="d-flex align-items-center">
						<li className="nav-item dropdown d-lg">
							<Link
								className="nav-link nav-profile d-flex align-items-center pe-0"
								to="/"
								data-bs-toggle="dropdown">
								<span className="d-none d-md-block dropdown-toggle ps-2">
									{user.name ? user.name : "NA"}
								</span>
							</Link>

							<ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
								<li className="dropdown-header">
									<h6>{user.name ? user.name : "NA"}</h6>
								</li>
								<li>
									<hr className="dropdown-divider" />
								</li>

								<li>
									<Link
										className="dropdown-item d-flex align-items-center"
										to="/profile">
										<i className="bi bi-person"></i>
										<span>My Profile</span>
									</Link>
								</li>
								<li>
									<hr className="dropdown-divider" />
								</li>

								<li>
									<Link
										onClick={handleLogout}
										className="dropdown-item d-flex align-items-center">
										<i className="bi bi-box-arrow-right"></i>
										<span>Logout</span>
									</Link>
								</li>
							</ul>
						</li>
					</ul>
				</nav>
			</header>

			<aside id="sidebar" className="sidebar">
				<ul className="sidebar-nav" id="sidebar-nav">
					{["Admin"].includes(userRole) ? (
						<li
							className="nav-item"
							onClick={() => {
								handleNavbar();
							}}>
							<Link className="nav-link " to="/dashboard">
								<i className="bi bi-grid"></i>
								<span>Dashboard</span>
							</Link>
						</li>
					) : null}

					{/* <!-- End Dashboard Nav --> */}
					{["Admin", "User"].includes(userRole) ? (
						<>
							<li className="nav-item">
								<Link
									className="nav-link collapsed"
									data-bs-target="#tables-nav"
									data-bs-toggle="collapse"
									to="/">
									<i className="bi bi-calendar-check"></i>
									<span>Appointment</span>
									<i className="bi bi-chevron-down ms-auto"></i>
								</Link>
								<ul
									id="tables-nav"
									className="nav-content collapse "
									data-bs-parent="#sidebar-nav">
									<li>
										<Link
											to="/appointment"
											onClick={() => {
												handleNavbar();
											}}>
											<i className="bi bi-circle"></i>
											<span>Book Appointment</span>
										</Link>
									</li>
									<li>
										<Link
											to="/appointmentHistory"
											onClick={() => {
												handleNavbar();
											}}>
											<i className="bi bi-circle"></i>
											<span>Appointment History</span>
										</Link>
									</li>
								</ul>
							</li>
						</>
					) : null}
					{/* <!-- End Login Page Nav --> */}

					{["Admin"].includes(userRole) ? (
						<li
							className="nav-item"
							onClick={() => {
								handleNavbar();
							}}>
							<Link className="nav-link collapsed" to="/user">
								<i className="bi bi-people"></i>
								<span>User</span>
							</Link>
						</li>
					) : null}
					{["Admin"].includes(userRole) ? (
						<li
							className="nav-item"
							onClick={() => {
								handleNavbar();
							}}>
							<Link className="nav-link collapsed" to="/service">
								<i className="bi bi-bandaid"></i>
								<span>Service</span>
							</Link>
						</li>
					) : null}
					{["Admin"].includes(userRole) ? (
						<li
							className="nav-item"
							onClick={() => {
								handleNavbar();
							}}>
							<Link className="nav-link collapsed" to="/holiday">
								<i className="bi bi-building-slash"></i>
								<span>Holiday</span>
							</Link>
						</li>
					) : null}

					{["Admin"].includes(userRole) ? (
						<li
							className="nav-item"
							onClick={() => {
								handleNavbar();
							}}>
							<Link className="nav-link collapsed" to="/notice">
								<i className="bi bi-chat-square-text"></i>
								<span>Notice</span>
							</Link>
						</li>
					) : null}
					{["Admin", "User"].includes(userRole) ? (
						<li
							className="nav-item"
							onClick={() => {
								handleNavbar();
							}}>
							<Link className="nav-link collapsed" to="/profile">
								<i className="bi bi-person"></i>
								<span>Profile</span>
							</Link>
						</li>
					) : null}

					<li className="nav-item" onClick={handleLogout}>
						<Link className="nav-link collapsed">
							<i className="bi bi-box-arrow-right"></i>
							<span>Logout </span>
						</Link>
					</li>
				</ul>
			</aside>

			<Outlet />
			<script src="../assets/js/main.js"></script>
		</>
	);
};

export default Layout;
