import * as actionTypes from "../actions";

export const initialState = {
	opened: false,
};

const customizationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.MENU_OPEN:
			return {
				...state,
				opened: action.sideBar,
			};
		default:
			return state;
	}
};

export default customizationReducer;
