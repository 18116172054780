import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer";

const store = configureStore({
	reducer,
	devTools: true,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
const persister = "Free";

export { store, persister };
