import * as actionTypes from "../actions";

export const initialState = {
	alert: false,
	msg: "",
	alertType: "",
};

const alertReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SHOW_ALERT:
			return {
				...state,
				alert: action.alert,
				msg: action.message,
				alertType: action.alertType,
			};
		default:
			return state;
	}
};

export default alertReducer;
