import axios from "axios";
import React, { useState, useEffect } from "react";
import validator from "../../helper/validator";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config/config.json";
const host = config.APP_URL;

const Service = (props) => {
	const navigate = useNavigate();
	const [service, setService] = useState({});
	const [serviceData, setServiceData] = useState([]);
	const [resp, setResp] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const result = validator.validateService.validate(service);
		const { error, value } = result;
		if (!error) {
			await axios
				.post(`${host}/api/admin/service`, value, {
					headers: {
						"Content-Type": "application/json",
						"x-access-token": localStorage.getItem("token"),
					},
				})
				.then((response) => {
					props.showLoader(false);
					props.showAlert(true, response.data.message, "success");
					setResp(true);
					if (response.data.token) {
						localStorage.setItem("token", response.data.token);
					}
				})
				.catch((err) => {
					props.showLoader(false);
					props.showAlert(true, err.response.data.error, "danger");
					if (err.response.status === 401) {
						localStorage.clear();
						navigate("/login");
					}
				});
		} else {
			props.showAlert(true, error.details[0].message, "danger");
		}
	};

	const deleteService = async (id) => {
		props.showLoader(true);
		await axios
			.delete(`${host}/api/admin/service/${id}`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				props.showLoader(false);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
				props.showAlert(true, response.data.message, "success");
				setResp(true);
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const getServiceData = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/admin/service`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				props.showLoader(false);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
				setServiceData(response.data.data);
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	useEffect(() => {
		getServiceData();
		setResp(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resp]);

	const handleChange = (e) => {
		setService({ ...service, [e.target.name]: e.target.value });
	};
	return (
		<>
			<main id="main" className="main">
				<div className="pagetitle">
					<div className="d-flex justify-content-between">
						<div>
							<h1>Services</h1>
							<nav>
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/dashboard">Home</Link>
									</li>
									<li className="breadcrumb-item active">Services</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>

				<div className="col-md-12">
					<div className="row">
						<div className="col-md-6">
							<div className="card">
								<div className="card-body">
									<h5 className="card-title">Add New Service</h5>
									<hr />
									<form className="row g-3">
										<div className="col-md-6">
											<label htmlFor="inputEmail5" className="form-label">
												Service Name
											</label>
											<input
												type="text"
												name="title"
												maxLength={25}
												placeholder="Service Name"
												onChange={handleChange}
												className="form-control"
											/>
										</div>
										<div className="col-md-6">
											<label htmlFor="inputZip" className="form-label">
												Status
											</label>
											<select
												className="form-select"
												name="status"
												onChange={handleChange}>
												<option value="">Select Status</option>
												<option value="Active">Active</option>
												<option value="Inactive">Inactive</option>
											</select>
										</div>
										<div className="col-md-6">
											<label htmlFor="inputCity" className="form-label">
												Price
											</label>
											<input
												type="text"
												className="form-control"
												name="price"
												onChange={handleChange}
												placeholder="Price (INR)"
											/>
										</div>
										<div className="col-md-6">
											<label htmlFor="inputCity" className="form-label">
												Limit
											</label>
											<input
												type="text"
												className="form-control"
												name="maxSlot"
												onChange={handleChange}
												placeholder="Maximum Booking"
											/>
										</div>
										<div className="text-center">
											<button
												type="button"
												className="btn btn-outline-success m-1"
												onClick={handleSubmit}>
												Add Service
											</button>
											<button
												type="reset"
												className="btn btn-outline-danger m-1">
												Reset
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>

						<div className="col-md-6">
							<div className="card">
								<div className="card-body">
									<h5 className="card-title">Service List</h5>
									{serviceData.length > 0 ? (
										<div className="table-responsive">
											<table className="table table-hover">
												<thead>
													<tr>
														<th scope="col">Sr. No.</th>
														<th scope="col">Title</th>
														<th scope="col">Price</th>
														<th scope="col">Limit</th>
														<th scope="col">Status</th>
														<th scope="col">Delete</th>
													</tr>
												</thead>
												<tbody>
													{serviceData.map((element, index) => {
														return (
															<tr key={index}>
																<th>{index + 1}</th>
																<td>{element.title}</td>
																<td>{element.price} INR</td>
																<td>{element.maxSlot}</td>
																<td>{element.status}</td>
																<td>
																	<button
																		className="btn btn-danger"
																		onClick={() => {
																			deleteService(element._id);
																		}}>
																		<span className="bi bi-trash"></span>
																	</button>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									) : (
										<div
											className="col-md-12 col-lg-12 d-flex justify-content-center align-items-center"
											style={{ height: "200px" }}>
											<h4>No Data Found</h4>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>

			{/* <!-- Vendor JS Files --> */}
			<script src="assets/vendor/apexcharts/apexcharts.min.js"></script>
			<script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
			<script src="assets/vendor/chart.js/chart.umd.js"></script>
			<script src="assets/vendor/echarts/echarts.min.js"></script>
			<script src="assets/vendor/quill/quill.min.js"></script>
			<script src="assets/vendor/simple-datatables/simple-datatables.js"></script>
			<script src="assets/vendor/tinymce/tinymce.min.js"></script>
			<script src="assets/vendor/php-email-form/validate.js"></script>

			{/* <!-- Template Main JS File --> */}
			<script src="assets/js/main.js"></script>
		</>
	);
};

export default Service;
