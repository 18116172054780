import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import roles from "../config/roles";
import Alert from "../components/alert/Alert";
import Dashboard from "../components/dashboard/Dashboard";
import PrivateRoute from "../components/PrivateRoutes";
import PublicRoute from "../components/PublicRoutes";
import Mainpage from "../components/mainpage/Mainpage";
import Notice from "../components/notice/Notice";
import Appointment from "../components/appointment/Appointment";
import Profile from "../components/profile/Profile";
import User from "../components/user/User";
import Service from "../components/service/Service";
import Holiday from "../components/holiday/Holiday";
import Receipt from "../components/receipt/Receipt";
import { useDispatch } from "react-redux";
import UpdateNotice from "../components/updateNotice/UpdateNotice";
import Loader from "../components/loader/Loader";
import Signup from "../components/signup/Signup";
import Login from "../components/login/Login";
import AppointmentHistory from "../components/appointmentHistory/AppointmentHistory";
import ForgotPassword from "../components/resetPassword/ResetPassword";
import ErrorPage from "../components/aborted/ErrorPage";
import CheckAvailibility from "../components/avalibility/CheckAvailibility";
import UserAppointmentHistory from "../components/userAppointmentHistory/UserAppointmentHistory";
import { SHOW_ALERT, SHOW_LOADER } from "../store/actions";
import Layout from "../layout/Layout";
// import PaginatedComponent from "../components/pagination/PaginatedComponent";

const BaseRoutes = () => {
	const dispatch = useDispatch();
	const showLoader = (loader, loaderMsg = "Loading") => {
		dispatch({
			type: SHOW_LOADER,
			loaderBar: loader,
			message: loaderMsg,
		});
	};

	const showAlert = (alert, alertMsg, alertType) => {
		dispatch({
			type: SHOW_ALERT,
			alert: alert,
			message: alertMsg,
			alertType: alertType,
		});
	};

	return (
		<HashRouter>
			<Loader />
			<Alert />
			<Routes>
				<Route
					index
					element={
						<PublicRoute>
							<Mainpage showAlert={showAlert} showLoader={showLoader} />
						</PublicRoute>
					}
				/>

				<Route
					path="/login"
					element={
						<PublicRoute>
							<Login showAlert={showAlert} showLoader={showLoader} />
						</PublicRoute>
					}
				/>

				<Route
					path="/checkAvailability"
					element={
						<PublicRoute>
							<CheckAvailibility
								showAlert={showAlert}
								showLoader={showLoader}
							/>
						</PublicRoute>
					}
				/>

				<Route
					path="/signup"
					element={
						<PublicRoute>
							<Signup showAlert={showAlert} showLoader={showLoader} />
						</PublicRoute>
					}
				/>

				<Route
					path="/reset_password"
					element={
						<PublicRoute>
							<ForgotPassword showAlert={showAlert} showLoader={showLoader} />
						</PublicRoute>
					}
				/>

				<Route element={<Layout showLoader={showLoader} />}>
					<Route
						path="/dashboard"
						element={
							<PrivateRoute roles={[roles.ADMIN]}>
								<Dashboard showAlert={showAlert} showLoader={showLoader} />
							</PrivateRoute>
						}
					/>

					<Route
						path="/notice"
						element={
							<PrivateRoute roles={[roles.ADMIN]}>
								<Notice showAlert={showAlert} showLoader={showLoader} />
							</PrivateRoute>
						}
					/>
					<Route
						path="/holiday"
						element={
							<PrivateRoute roles={[roles.ADMIN]}>
								<Holiday showAlert={showAlert} showLoader={showLoader} />
							</PrivateRoute>
						}
					/>
					<Route
						path="/service"
						element={
							<PrivateRoute roles={[roles.ADMIN]}>
								<Service showAlert={showAlert} showLoader={showLoader} />
							</PrivateRoute>
						}
					/>

					<Route
						path="/user"
						element={
							<PrivateRoute roles={[roles.ADMIN]}>
								<User showAlert={showAlert} showLoader={showLoader} />
							</PrivateRoute>
						}
					/>
					<Route
						path="/appointment"
						element={
							<PrivateRoute roles={[roles.ADMIN, roles.USER]}>
								<Appointment showAlert={showAlert} showLoader={showLoader} />
							</PrivateRoute>
						}
					/>

					<Route
						path="/appointmentHistory"
						element={
							<PrivateRoute roles={[roles.ADMIN, roles.USER]}>
								<AppointmentHistory
									showAlert={showAlert}
									showLoader={showLoader}
								/>
							</PrivateRoute>
						}
					/>

					<Route
						path="/profile"
						element={
							<PrivateRoute roles={[roles.USER, roles.ADMIN]}>
								<Profile showAlert={showAlert} showLoader={showLoader} />
							</PrivateRoute>
						}
					/>
					<Route
						path="/updateNotice"
						element={
							<PrivateRoute roles={[roles.ADMIN]}>
								<UpdateNotice showAlert={showAlert} showLoader={showLoader} />
							</PrivateRoute>
						}
					/>

					<Route
						path="/userAppointments/:id"
						element={
							<PrivateRoute roles={[roles.ADMIN]}>
								<UserAppointmentHistory
									showAlert={showAlert}
									showLoader={showLoader}
								/>
							</PrivateRoute>
						}
					/>
				</Route>

				<Route
					path="/receipt/:id"
					element={
						<PrivateRoute roles={[roles.USER, roles.ADMIN]}>
							<Receipt showAlert={showAlert} showLoader={showLoader} />
						</PrivateRoute>
					}
				/>

				<Route
					path="/error"
					element={
						<PrivateRoute roles={[roles.ADMIN]}>
							<ErrorPage showAlert={showAlert} showLoader={showLoader} />
						</PrivateRoute>
					}
				/>

				{/* <Route
					path="/page"
					element={
						<PrivateRoute roles={[roles.ADMIN]}>
							<PaginatedComponent
								showAlert={showAlert}
								showLoader={showLoader}
							/>
						</PrivateRoute>
					}
				/> */}
			</Routes>
		</HashRouter>
	);
};

export default BaseRoutes;
