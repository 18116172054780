import React, { useEffect, useState } from "react";
import moment from "moment";
import config from "../../config/config.json";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const host = config.APP_URL;

const AppointmentHistory = (props) => {
	const navigate = useNavigate();
	const [bookingData, setBookingData] = useState([]);

	const getBookingDetails = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/user/appointment`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				props.showLoader(false);
				setBookingData(response.data.data);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	useEffect(() => {
		getBookingDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<main id="main" className="main">
				<div className="pagetitle">
					<h1>Appointment History</h1>
					<nav>
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/appointment">Appoinment</Link>
							</li>
							<li className="breadcrumb-item active">Appointment History</li>
						</ol>
					</nav>
				</div>

				<div className="row">
					<div className="col-xl-12">
						<div
							className="card"
							style={{ width: "100%", marginBottom: "0px" }}>
							<div className="card-body pt-3">
								{bookingData.length > 0 ? (
									<div className="accordion" id="accordionExample">
										{bookingData.map((element, index) => {
											return (
												<div className="accordion-item" key={index + 1}>
													<h2 className="accordion-header" id="headingOne">
														<button
															className="accordion-button collapsed"
															type="button"
															data-bs-toggle="collapse"
															data-bs-target={`#collapse${index + 1}`}
															aria-expanded="false">
															{moment
																.utc(element.bookingDate)
																.format("DD-MM-YYYY")}
														</button>
													</h2>
													<div
														id={`collapse${index + 1}`}
														className="accordion-collapse collapse"
														aria-labelledby="headingOne"
														data-bs-parent="#accordionExample">
														<div className="accordion-body">
															<div className="row">
																<div className="col-lg-3 col-md-4 label">
																	Reference Number
																</div>
																<div className="col-lg-9 col-md-8 profileContent">
																	{element.referenceNumber}
																</div>
															</div>

															<div className="row">
																<div className="col-lg-3 col-md-4 label">
																	Appointment Date
																</div>
																<div className="col-lg-9 col-md-8 profileContent">
																	{moment
																		.utc(element.bookingDate)
																		.format("DD/MM/YYYY hh:mm:ss A")}
																</div>
															</div>

															<div className="row">
																<div className="col-lg-3 col-md-4 label">
																	Service
																</div>
																<div className="col-lg-9 col-md-8 profileContent">
																	{element.patientType}
																</div>
															</div>

															<div className="row">
																<div className="col-lg-3 col-md-4 label">
																	Appointment Status
																</div>
																<div className="col-lg-9 col-md-8 profileContent">
																	{element.bookingStatus}
																</div>
															</div>

															<div className="row">
																<div className="col-lg-3 col-md-4 label">
																	Name
																</div>
																<div className="col-lg-9 col-md-8 profileContent">
																	{element.patientName}
																</div>
															</div>

															<div className="row">
																<div className="col-lg-3 col-md-4 label">
																	Mobile
																</div>
																<div className="col-lg-9 col-md-8 profileContent">
																	{element.patientMobile}
																</div>
															</div>

															<div className="row">
																<div className="col-lg-3 col-md-4 label">
																	Gender
																</div>
																<div className="col-lg-9 col-md-8 profileContent">
																	{element.patientGender}
																</div>
															</div>

															<div className="row">
																<div className="col-lg-3 col-md-4 label">
																	Aadhaar
																</div>
																<div className="col-lg-9 col-md-8 profileContent">
																	{element.patientAadhar}
																</div>
															</div>

															<div className="row">
																<div className="col-lg-3 col-md-4 label">
																	Address
																</div>
																<div className="col-lg-9 col-md-8 profileContent">
																	{element.patientAddress}
																</div>
															</div>

															<div className="row">
																<div className="col-lg-3 col-md-4 label">
																	Booking Date
																</div>
																<div className="col-lg-9 col-md-8 profileContent">
																	{moment
																		.utc(element.bookedOn)
																		.format("DD-MMM-YYYY hh:mm:ss A")}
																</div>
															</div>

															{element.patientAttachment ||
															element.patientAttachment === null ||
															undefined ? (
																<div className="row">
																	<div className="col-lg-3 col-md-4 label">
																		Attachment
																	</div>
																	<div className="col-lg-9 col-md-8 profileContent">
																		<a
																			href={`${host}${element.patientAttachment}`}
																			target="_blank"
																			rel="noreferrer"
																			className="text-decoration-none">
																			Click Here To Download
																		</a>
																	</div>
																</div>
															) : null}
															<button
																className="btn btn-success mt-2"
																type="button"
																onClick={() => {
																	navigate(`/receipt/${element._id}`);
																}}>
																Print Reciept
															</button>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								) : (
									<div
										className="col-md-12 col-lg-12 d-flex justify-content-center align-items-center"
										style={{ height: "200px" }}>
										<h4>No Data Found</h4>
									</div>
								)}

								{/* <ul
									className="nav nav-tabs nav-tabs-bordered"
									id="borderedTab"
									role="tablist">
									<li className="nav-item" role="presentation">
										<button
											className="nav-link active"
											id="home-tab"
											data-bs-toggle="tab"
											data-bs-target="#bordered-home"
											type="button"
											role="tab"
											aria-controls="home"
											aria-selected="true">
											Book Appointment
										</button>
									</li>
									<li className="nav-item" role="presentation">
										<button
											className="nav-link"
											id="profile-tab"
											data-bs-toggle="tab"
											onClick={getBookingDetails}
											data-bs-target="#bordered-profile"
											type="button"
											role="tab"
											aria-controls="profile"
											aria-selected="false">
											Appointment History
										</button>
									</li>
								</ul>
								<div className="tab-content pt-2" id="borderedTabContent">
									<div
										className="tab-pane fade show active"
										id="bordered-home"
										role="tabpanel"
										aria-labelledby="home-tab"></div>
									<div
										className="tab-pane fade"
										id="bordered-profile"
										role="tabpanel"
										aria-labelledby="profile-tab">
										
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default AppointmentHistory;
