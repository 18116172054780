import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
	const navigate = useNavigate();
	const [countdown, setCountdown] = useState(10000);

	useEffect(() => {
		const countdownInterval = setInterval(() => {
			setCountdown((prevCountdown) => prevCountdown - 1000);
		}, 1000);

		const redirectTimeout = setTimeout(() => {
			clearInterval(countdownInterval);
			navigate("/appointment");
		}, countdown);

		return () => {
			clearInterval(countdownInterval);
			clearTimeout(redirectTimeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	return (
		<>
			<div>
				<main>
					<div className="container">
						<section className="register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
							<div className="container">
								<div className="row justify-content-center">
									<div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
										{/* <!-- End Logo --> */}

										<div className="card mb-3 loginBorderRadius">
											<div
												className="card-body cbw"
												style={{ width: "1200px" }}>
												<div className="pt-4 pb-2">
													<div className="d-flex align-items-center justify-content-center ">
														<i
															class="bi bi-exclamation-circle-fill color-danger"
															style={{ fontSize: "60px" }}></i>
													</div>

													<h5 className="card-title text-center pb-4 fs-3">
														Payment Failed
													</h5>
													<p className="fs-4 text-center">
														Your Payment has been Failed.
													</p>
													{/* <p className="fs-5 text-center"></p> */}
													<p className="fs-6 text-center">
														If you have any queries, Please Contact to Sant
														Geetanjali Chikitsalaya
													</p>

													<p className="fs-6 text-center">
														Will be redirect within {countdown / 1000} seconds
													</p>
												</div>

												<form
													className="row g-3 needs-validation mt-2 mb-4"
													noValidate>
													<div className=" mb-1 text-center">
														<button
															className="btn"
															style={{
																backgroundColor: "#e57e80",
																color: "black",
																border: "none",
															}}
															type="button ">
															<b>Click Here to Redirect</b>
														</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</main>
				{/* <!-- End #main --> */}
				{/* <!-- Vendor JS Files --> */}
				<script src="../../assets/vendor/apexcharts/apexcharts.min.js"></script>
				<script src="../../assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
				<script src="../../assets/vendor/chart.js/chart.umd.js"></script>
				<script src="../../assets/vendor/echarts/echarts.min.js"></script>
				<script src="../../assets/vendor/quill/quill.min.js"></script>
				<script src="../../assets/vendor/simple-datatables/simple-datatables.js"></script>
				<script src="../../assets/vendor/tinymce/tinymce.min.js"></script>
				<script src="../../assets/vendor/php-email-form/validate.js"></script>
				{/* <!-- Template Main JS File --> */}
				<script src="../../assets/js/main.js"></script>
			</div>
		</>
	);
};

export default ErrorPage;
