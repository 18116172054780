import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config/config.json";
import moment from "moment/moment";
const host = config.APP_URL;

const User = (props) => {
	const navigate = useNavigate();
	const [users, setUsers] = useState([]);
	const [role, setRole] = useState(true);
	const [filterUser, setFilterUser] = useState(null);
	const [resp, setResp] = useState(false);

	const getUsers = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/admin/user?role=${role}&filter=${filterUser}`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				props.showLoader(false);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
				setUsers(response.data.users);
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const changeUserRole = async (e) => {
		e.preventDefault();
		props.showLoader(true);
		await axios
			.post(
				`${host}/api/admin/changeRole?user=${e.target.getAttribute("user")}`,
				{ status: e.target.getAttribute("value") },
				{
					headers: {
						"Content-Type": "application/json",
						"x-access-token": localStorage.getItem("token"),
					},
				}
			)
			.then((response) => {
				props.showLoader(false);
				setResp(true);
				props.showAlert(true, response.data.message, "success");
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const handleStatus = async (e) => {
		e.preventDefault();
		props.showLoader(true);
		await axios
			.post(
				`${host}/api/admin/updateUserStatus?user=${e.target.getAttribute(
					"user"
				)}`,
				{ status: e.target.getAttribute("value") },
				{
					headers: {
						"Content-Type": "application/json",
						"x-access-token": localStorage.getItem("token"),
					},
				}
			)
			.then((response) => {
				props.showLoader(false);
				setResp(true);
				props.showAlert(true, response.data.message, "success");
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	useEffect(() => {
		getUsers();
		setResp(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [role, resp, filterUser]);

	return (
		<>
			<main id="main" className="main">
				<div className="pagetitle">
					<div>
						<h1>Users</h1>
						<nav>
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<Link to="/dashboard">Home</Link>
								</li>
								<li className="breadcrumb-item active">Users</li>
							</ol>
						</nav>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="d-flex justify-content-between">
							<h5 className="card-title">All Users List</h5>

							<div className="mt-3">
								<div className="search-bar">
									<div className="search-form d-flex align-items-center">
										<div className="col-md-4">
											<select
												className="form-select"
												onChange={(e) => {
													setRole(e.target.value);
												}}
												required={true}
												name="role">
												<option value={true}>User</option>
												<option value={false}>Admin</option>
											</select>
										</div>
										&nbsp;
										<input
											type="text"
											name="query"
											value={filterUser}
											onChange={(e) => {
												setFilterUser(e.target.value);
											}}
											placeholder="Search"
											title="Enter search keyword"
											className="form-control"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="table-responsive" style={{ minHeight: "450px" }}>
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col">Sr. No.</th>
										<th scope="col">Name</th>
										<th scope="col">Gender</th>
										<th scope="col">Email</th>
										<th scope="col">Mobile</th>
										<th scope="col">Address</th>
										<th scope="col">Registered On</th>
										<th scope="col">Role</th>
										<th scope="col">Status</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{users.map((element, index) => {
										return (
											<tr key={index}>
												<th scope="row">
													<span>{index + 1}</span>
												</th>
												<td>{element.name ? element.name : "NA"}</td>
												<td>{element.gender ? element.gender : "NA"}</td>
												<td>{element.email ? element.email : "NA"}</td>
												<td>{element.mobile ? element.mobile : "NA"}</td>
												<td>{element.address ? element.address : "NA"}</td>
												<td>
													{element.date
														? moment(element.date).format("DD-MMM-YYYY hh:mm A")
														: "NA"}
												</td>
												{/* <td>{element.is_user ? "User" : "Admin"}</td> */}
												<td>
													<button
														className={
															!element.is_user
																? "btn btn-danger"
																: "btn btn-success"
														}
														onClick={changeUserRole}
														value={element.is_user ? false : true}
														user={element._id}
														type="button">
														{!element.is_user ? "Admin" : "User"}
													</button>
												</td>
												<td>
													<button
														className={
															element.is_active
																? "btn btn-success"
																: "btn btn-danger"
														}
														onClick={handleStatus}
														value={element.is_active ? false : true}
														user={element._id}
														type="button">
														{element.is_active ? "Active" : "Inactive"}
													</button>
												</td>
												<td>
													<Link to={`/userAppointments/${element._id}`}>
														<i className="fa-solid fa-arrow-right pt-2"></i>
													</Link>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</main>

			{/* <!-- Vendor JS Files --> */}
			<script src="assets/vendor/apexcharts/apexcharts.min.js"></script>
			<script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
			<script src="assets/vendor/chart.js/chart.umd.js"></script>
			<script src="assets/vendor/echarts/echarts.min.js"></script>
			<script src="assets/vendor/quill/quill.min.js"></script>
			<script src="assets/vendor/simple-datatables/simple-datatables.js"></script>
			<script src="assets/vendor/tinymce/tinymce.min.js"></script>
			<script src="assets/vendor/php-email-form/validate.js"></script>

			{/* <!-- Template Main JS File --> */}
			<script src="assets/js/main.js"></script>
		</>
	);
};

export default User;
