import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children, roles }) => {
	const location = useLocation();
	const userType = localStorage.getItem("role");
	const accessToken = localStorage.getItem("token");

	const userHasRequiredRole = roles.includes(userType);

	if (!accessToken) {
		return <Navigate to="/" state={{ from: location }} replace />;
	}

	if (accessToken && !userHasRequiredRole) {
		userType === "User" ? (
			<Navigate to="/appointment" state={{ from: location }} replace />
		) : (
			<Navigate to="/dashboard" state={{ from: location }} replace />
		);
	}

	return children;
};

export default PrivateRoute;
