import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config/config.json";
import validator from "../../helper/validator";
const host = config.APP_URL;

const Notice = (props) => {
	const navigate = useNavigate();
	const [noticeData, setNoticeData] = useState([]);
	const [noticePayload, setNoticePayload] = useState({});
	const [resp, setResp] = useState(false);
	const getNoticeInformation = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/admin/notice`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				props.showLoader(false);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
				setNoticeData(response.data.data);
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	useEffect(() => {
		getNoticeInformation();
		setResp(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resp]);

	const deleteNotice = async (id) => {
		props.showLoader(true);
		await axios
			.delete(`${host}/api/admin/notice/${id}`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				props.showLoader(false);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
				props.showAlert(true, response.data.message, "success");
				setResp(true);
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const result = validator.validateNotice.validate(noticePayload);
		const { error, value } = result;
		if (!error) {
			props.showLoader(true);
			await axios
				.post(`${host}/api/admin/notice`, value, {
					headers: {
						"Content-Type": "application/json",
						"x-access-token": localStorage.getItem("token"),
					},
				})
				.then((response) => {
					props.showLoader(false);
					props.showAlert(true, response.data.message, "success");
					if (response.data.token) {
						localStorage.setItem("token", response.data.token);
					}
				})
				.catch((err) => {
					props.showLoader(false);
					props.showAlert(true, err.response.data.error, "danger");
					if (err.response.status === 401) {
						localStorage.clear();
						navigate("/login");
					}
				});
		} else {
			props.showAlert(true, error.details[0].message, "danger");
		}
	};

	const handleChange = (e) => {
		setNoticePayload({ ...noticePayload, [e.target.name]: e.target.value });
	};

	return (
		<>
			<main id="main" className="main">
				<div className="pagetitle">
					<div className="d-flex justify-content-between">
						<div>
							<h1>Notice</h1>
							<nav>
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/dashboard">Home</Link>
									</li>
									<li className="breadcrumb-item active">Notice</li>
								</ol>
							</nav>
						</div>
						<div>
							<button
								type="button"
								className="btn btn-outline-primary mt-2"
								data-bs-toggle="modal"
								data-bs-target="#addNoticeModal">
								+
							</button>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<h5 className="card-title">All Notice/Information List</h5>

						{/* <!--Table with hoverable rows --> */}
						<div className="table-responsive">
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col">Sr. No.</th>
										<th scope="col">Title (English)</th>
										<th scope="col">Title (Hindi)</th>
										<th scope="col">Status</th>
										<th scope="col">Delete</th>
									</tr>
								</thead>
								<tbody>
									{noticeData.map((element, index) => {
										return (
											<tr key={index + 1}>
												<th scope="row">{index + 1}</th>
												<td>{element.titleEnglish}</td>
												<td>{element.titleHindi}</td>
												<td>{element.status}</td>
												<td>
													<button
														onClick={() => {
															deleteNotice(element._id);
														}}
														type="button"
														className="btn btn-danger"
														// data-bs-toggle="modal"
														// data-bs-target="#ExtralargeModal"
													>
														<i className="bi bi-trash" aria-hidden="true"></i>
													</button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						{/* <!---  End Table with hoverable rows --> */}

						{/* <!-- Bordered Tabs --> */}
					</div>
				</div>

				<div className="modal fade" id="ExtralargeModal" tabIndex="-1">
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Update Notice/Information</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"></button>
							</div>
							<div className="modal-body">
								<section>
									<div className="row">
										<div className="col-lg-12">
											<div className="card">
												<div className="card-body">
													{/* <!-- General Form Elements --> */}
													<form>
														<div className="col-sm-12 d-flex">
															<div className="col-sm-6">
																<div className="row m-3">
																	<label className="col-sm-3 col-form-label mx-1">
																		Type
																	</label>
																	<div className="col-sm-8">
																		<select
																			className="form-select"
																			aria-label="Default select example">
																			<option selected>
																				-- Select Type --
																			</option>
																			<option value="1">Notice</option>
																			<option value="2">Information</option>
																		</select>
																	</div>
																</div>
															</div>
															<div className="col-sm-6">
																<div className="row m-3">
																	<label className="col-sm-3 col-form-label mx-1">
																		Status
																	</label>
																	<div className="col-sm-8">
																		<select
																			className="form-select"
																			aria-label="Default select example">
																			<option selected>
																				-- Select Status --
																			</option>
																			<option value="1">Active</option>
																			<option value="2">Inactive</option>
																		</select>
																	</div>
																</div>
															</div>
														</div>

														<div className="row mb-3">
															<label
																htmlFor="inputText"
																className="col-sm-2 col-form-label">
																Title (Hindi)
															</label>
															<div className="col-sm-10 modalform">
																<input type="text" className="form-control" />
															</div>
														</div>

														<div className="row mb-3">
															<label
																htmlFor="inputText"
																className="col-sm-2 col-form-label">
																Title (English)
															</label>
															<div className="col-sm-10 modalform">
																<input type="text" className="form-control" />
															</div>
														</div>

														<div className="d-flex flex-row-reverse">
															<button
																type="submit"
																className="btn btn-outline-success mx-1">
																Update
															</button>
														</div>
													</form>
													{/* <!-- End General Form Elements --> */}
												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="addNoticeModal" tabIndex="-1">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Add New Notice/Information</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"></button>
							</div>
							<div className="modal-body">
								<section>
									<div className="row">
										<div className="col-lg-12">
											<div className="card">
												<div className="card-body">
													<form>
														<div className="col-sm-12 d-flex">
															<div className="col-sm-6">
																<div className="row m-3">
																	<label className="col-sm-3 col-form-label mx-1">
																		Type
																	</label>
																	<div className="col-sm-8">
																		<select
																			name="type"
																			onChange={handleChange}
																			className="form-select"
																			aria-label="Default select example">
																			<option value="">
																				-- Select Type --
																			</option>
																			<option value="Notice">Notice</option>
																			<option value="Information">
																				Information
																			</option>
																		</select>
																	</div>
																</div>
															</div>
															<div className="col-sm-6">
																<div className="row m-3">
																	<label className="col-sm-3 col-form-label mx-1">
																		Status
																	</label>
																	<div className="col-sm-8">
																		<select
																			name="status"
																			onChange={handleChange}
																			className="form-select"
																			aria-label="Default select example">
																			<option value="">
																				-- Select Status --
																			</option>
																			<option value="Active">Active</option>
																			<option value="Inactive">Inactive</option>
																		</select>
																	</div>
																</div>
															</div>
														</div>

														<div className="row mb-3">
															<label
																htmlFor="inputText"
																className="col-sm-2 col-form-label">
																Title (Hindi)
															</label>
															<div className="col-sm-10 modalform">
																<input
																	type="text"
																	placeholder="Enter Title (Hindi)"
																	value={noticePayload.titleHindi}
																	className="form-control"
																	name="titleHindi"
																	maxLength={100}
																	onChange={handleChange}
																/>
															</div>
														</div>

														<div className="row mb-3">
															<label
																htmlFor="inputText"
																className="col-sm-2 col-form-label">
																Title (English)
															</label>
															<div className="col-sm-1 modalform">
																<input
																	type="text"
																	placeholder="Enter Title (English)"
																	value={noticePayload.titleEnglish}
																	className="form-control"
																	name="titleEnglish"
																	maxLength={100}
																	onChange={handleChange}
																/>
															</div>
														</div>

														<div className="d-flex flex-row-reverse">
															<button
																type="reset"
																className="btn btn-outline-danger mx-1">
																Reset
															</button>
															<button
																type="submit"
																onClick={handleSubmit}
																className="btn btn-outline-success mx-1">
																Submit
															</button>
														</div>
													</form>
													{/* <!-- End General Form Elements --> */}
												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</div>
			</main>

			{/* <!-- Vendor JS Files --> */}
			<script src="assets/vendor/apexcharts/apexcharts.min.js"></script>
			<script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
			<script src="assets/vendor/chart.js/chart.umd.js"></script>
			<script src="assets/vendor/echarts/echarts.min.js"></script>
			<script src="assets/vendor/quill/quill.min.js"></script>
			<script src="assets/vendor/simple-datatables/simple-datatables.js"></script>
			<script src="assets/vendor/tinymce/tinymce.min.js"></script>
			<script src="assets/vendor/php-email-form/validate.js"></script>

			{/* <!-- Template Main JS File --> */}
			<script src="assets/js/main.js"></script>
		</>
	);
};

export default Notice;
