import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {
	const loaderState = useSelector((store) => store.loaders);

	if (loaderState.loader) {
		document.getElementById("root").classList.add("loader-on");
	} else {
		document.getElementById("root").classList.remove("loader-on");
	}

	return (
		<>
			{loaderState.loader && (
				<div className="loaderOuter">
					<span className="loader"></span>
				</div>
			)}
		</>
	);
};

export default Loader;
