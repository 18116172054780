import { combineReducers } from "redux";
import customizationReducer from "./reducers/customizationReducer";
import loaderReducer from "./reducers/loaderReducers";
import alertReducer from "./reducers/alertReducers";

const reducer = combineReducers({
	customization: customizationReducer,
	loaders: loaderReducer,
	alert: alertReducer,
});

export default reducer;
