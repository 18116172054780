import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import validator from "../../helper/validator";
import config from "../../config/config.json";
import { useNavigate } from "react-router-dom";
const host = config.APP_URL;

const Profile = (props) => {
	const navigate = useNavigate();
	const [profile, setProfile] = useState({});
	const getProfile = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/user/profile`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				props.showLoader(false);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
				setProfile(response.data.data);
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const handleProfile = async (e) => {
		e.preventDefault();
		props.showLoader(true);
		delete profile.date;
		delete profile.lastLogin;
		const result = validator.validateUpdateProfile.validateAsync(profile);
		const { error } = result;
		if (!error) {
			props.showLoader(true);
			await axios
				.post(`${host}/api/user/profile`, profile, {
					headers: {
						"Content-Type": "application/json",
						"x-access-token": localStorage.getItem("token"),
					},
				})
				.then((response) => {
					props.showLoader(false);
					props.showAlert(true, response.data.message, "success");
					if (response.data.token) {
						localStorage.setItem("token", response.data.token);
					}
				})
				.catch((err) => {
					props.showLoader(false);
					props.showAlert(true, err.response.data.error, "danger");
					if (err.response.status === 401) {
						localStorage.clear();
						navigate("/login");
					}
				});
		} else {
			props.showAlert(true, error.details[0].message, "danger");
		}
	};

	useEffect(() => {
		getProfile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e) => {
		setProfile({ ...profile, [e.target.name]: e.target.value });
	};
	return (
		<>
			<main id="main" className="main">
				<div className="pagetitle">
					<h1>Profile</h1>
					<nav>
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link>Home</Link>
							</li>
							<li className="breadcrumb-item active">Profile</li>
						</ol>
					</nav>
				</div>
				{/* <!-- End Page Title --> */}

				<section className="profile">
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body pt-3">
									{/* <!-- Bordered Tabs --> */}
									<ul className="nav nav-tabs nav-tabs-bordered">
										<li className="nav-item">
											<button
												className="nav-link active"
												data-bs-toggle="tab"
												data-bs-target="#profile-overview">
												Details
											</button>
										</li>

										<li className="nav-item">
											<button
												className="nav-link"
												data-bs-toggle="tab"
												data-bs-target="#profile-edit">
												Edit Profile
											</button>
										</li>
									</ul>
									<div className="tab-content pt-2">
										<div
											className="tab-pane fade show active profile-overview"
											id="profile-overview">
											<h5 className="card-title">Profile Details</h5>

											<div className="row">
												<div className="col-lg-3 col-md-4 label">Name</div>
												<div className="col-lg-9 col-md-8 profileContent">
													{profile.name ? profile.name : "NA"}
												</div>
											</div>

											<div className="row">
												<div className="col-lg-3 col-md-4 label">Email</div>
												<div className="col-lg-9 col-md-8 profileContent">
													{profile.email ? profile.email : "NA"}
												</div>
											</div>

											<div className="row">
												<div className="col-lg-3 col-md-4 label">Mobile</div>
												<div className="col-lg-9 col-md-8 profileContent">
													{profile.mobile}
												</div>
											</div>

											<div className="row">
												<div className="col-lg-3 col-md-4 label">
													Aadhar Number
												</div>
												<div className="col-lg-9 col-md-8 profileContent">
													{profile.aadhaar ? profile.aadhaar : "NA"}
												</div>
											</div>

											<div className="row">
												<div className="col-lg-3 col-md-4 label">Address</div>
												<div className="col-lg-9 col-md-8 profileContent">
													{profile.address ? profile.address : "NA"}
												</div>
											</div>

											<div className="row">
												<div className="col-lg-3 col-md-4 label">
													Registration Date
												</div>
												<div className="col-lg-9 col-md-8 profileContent">
													{profile.date
														? moment(profile.date).format(
																"DD MMM YYYY hh:mm:ss A"
														  )
														: null}
												</div>
											</div>

											<div className="row">
												<div className="col-lg-3 col-md-4 label">
													Last Login
												</div>
												<div className="col-lg-9 col-md-8 profileContent">
													{profile.lastLogin
														? moment(profile.lastLogin).format(
																"DD MMM YYYY hh:mm:ss A"
														  )
														: null}
												</div>
											</div>
										</div>

										<div
											className="tab-pane fade profile-edit pt-3"
											id="profile-edit">
											{/* <!-- Profile Edit Form --> */}
											<form>
												<div className="row mb-3">
													<label
														htmlFor="fullName"
														className="col-md-4 col-lg-3 col-form-label">
														Name
													</label>
													<div className="col-md-8 col-lg-9">
														<input
															name="name"
															type="text"
															className="form-control"
															onChange={handleChange}
															value={profile.name}
														/>
													</div>
												</div>

												<div className="row mb-3">
													<label
														htmlFor="company"
														className="col-md-4 col-lg-3 col-form-label">
														Email
													</label>
													<div className="col-md-8 col-lg-9">
														<input
															name="email"
															type="email"
															onChange={handleChange}
															className="form-control"
															value={profile.email}
														/>
													</div>
												</div>

												<div className="row mb-3">
													<label
														htmlFor="Country"
														className="col-md-4 col-lg-3 col-form-label">
														Mobile Number
													</label>
													<div className="col-md-8 col-lg-9">
														<input
															name="mobile"
															type="text"
															onChange={handleChange}
															className="form-control"
															value={profile.mobile}
														/>
													</div>
												</div>

												<div className="row mb-3">
													<label
														htmlFor="Address"
														className="col-md-4 col-lg-3 col-form-label">
														Aadhar Number
													</label>
													<div className="col-md-8 col-lg-9">
														<input
															name="aadhaar"
															type="text"
															maxLength={12}
															onChange={handleChange}
															className="form-control"
															value={profile.aadhaar}
														/>
													</div>
												</div>

												<div className="row mb-3">
													<label
														htmlFor="Phone"
														className="col-md-4 col-lg-3 col-form-label">
														Address
													</label>
													<div className="col-md-8 col-lg-9">
														<input
															name="address"
															type="text"
															onChange={handleChange}
															className="form-control"
															value={profile.address}
														/>
													</div>
												</div>

												<div className="text-center">
													<button
														type="button"
														className="btn btn-outline-success"
														onClick={handleProfile}>
														Update Profile
													</button>

													<button
														className="btn btn-outline-danger ms-2"
														type="reset">
														Reset
													</button>
												</div>
											</form>
											{/* <!-- End Profile Edit Form --> */}
										</div>
									</div>
									{/* <!-- End Bordered Tabs --> */}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			{/* <!-- End #main -->

		<!-- Vendor JS Files --> */}
			<script src="../../assets/vendor/apexcharts/apexcharts.min.js"></script>
			<script src="../../assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
			<script src="../../assets/vendor/chart.js/chart.umd.js"></script>
			<script src="../../assets/vendor/echarts/echarts.min.js"></script>
			<script src="../../assets/vendor/quill/quill.min.js"></script>
			<script src="../../assets/vendor/simple-datatables/simple-datatables.js"></script>
			<script src="../../assets/vendor/tinymce/tinymce.min.js"></script>
			<script src="../../assets/vendor/php-email-form/validate.js"></script>

			{/* <!-- Template Main JS File --> */}
			<script src="../../assets/js/main.js"></script>
		</>
	);
};

export default Profile;
