import React, { useState } from "react";
import sgc_logo from "../../assets/img/sgc_logo.jpeg";
import validator from "../../helper/validator";
import config from "../../config/config.json";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";

const Login = (props) => {
	// document.addEventListener("contextmenu", function (e) {
	// 	e.preventDefault();
	// });
	const navigate = useNavigate();
	const host = config.APP_URL;
	const [username, setUsername] = useState({ id: "", password: "" });

	const handleSubmit = async (e) => {
		e.preventDefault();
		const result = validator.validateLogin.validate(username);
		const { error, value } = result;
		if (!error) {
			props.showLoader(true, "Authenticating");
			await axios
				.post(`${host}/api/auth/login`, value, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((response) => {
					props.showLoader(false);
					props.showAlert(true, response.data.message, "success");
					localStorage.setItem("userId", response.data.data.id);
					localStorage.setItem("token", response.data.data.token);
					localStorage.setItem("role", response.data.data.role);
					localStorage.setItem("name", response.data.data.name);
					if (response.data.data.role === "User") {
						navigate("/appointment");
					} else {
						navigate("/dashboard");
					}
				})
				.catch((err) => {
					props.showLoader(false);
					props.showAlert(true, err.response.data.error, "danger");
					if (err.response.status === 401) {
						localStorage.clear();
						navigate("/login");
					}
				});
		} else {
			props.showLoader(false);
			props.showAlert(true, error.details[0].message, "danger");
		}
	};

	const handleChange = (e) => {
		setUsername({ ...username, [e.target.name]: e.target.value });
	};

	return (
		<div className="loginOuter">
			<main style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
				<button
					className="btn"
					style={{
						position: "absolute",
						top: "25px",
						left: "25px",
						backgroundColor: "#e57e80",
						color: "black",
						border: "none",
					}}
					onClick={() => {
						navigate("/");
					}}>
					<i className="bi bi-arrow-left-circle"></i>&nbsp; Back
				</button>
				<div className="container">
					<section className="register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
									{/* <!-- End Logo --> */}

									<div className="card mb-3 loginBorderRadius">
										<div className="card-body">
											<div className="pt-4 pb-2">
												<div className="d-flex align-items-center justify-content-center ">
													<img
														src={sgc_logo}
														className="img-fluid"
														alt="Sant Gitanjali Chikitsalaya"
													/>
												</div>

												<h5 className="card-title text-center pb-0 fs-4">
													संत गीतांजली चिकित्सालय
												</h5>
											</div>

											<form className="row g-3 needs-validation" noValidate>
												<div className="col-12">
													<label htmlFor="yourUsername" className="form-label">
														Mobile or Email
													</label>
													<div className="input-group has-validation">
														<span
															className="input-group-text"
															id="inputGroupPrepend">
															<i className="bi bi-phone"></i>
														</span>
														<input
															type="text"
															name="id"
															onChange={handleChange}
															value={username.id}
															minLength={5}
															maxLength={50}
															className="form-control"
															required
															placeholder="123xxxx789"
														/>
														<div className="invalid-feedback">
															Please Enter Your Mobile No or Email.
														</div>
													</div>
												</div>

												<div className="col-12">
													<label
														htmlFor="verificationCode"
														className="form-label">
														Password
													</label>
													<div className="input-group has-validation">
														<span className="input-group-text">
															<i className="bi bi-key"></i>
														</span>
														<input
															type="password"
															maxLength={12}
															name="password"
															onChange={handleChange}
															className="form-control"
															value={username.password}
															required
															autoComplete="off"
															placeholder="XXXXXXXXXXXXXX"
														/>
														<div className="invalid-feedback">
															Please Enter Your Password.
														</div>
													</div>
												</div>

												<div className="col-12 my-4 mb-1">
													<button
														className="btn w-100"
														style={{
															backgroundColor: "#e57e80",
															color: "black",
															border: "none",
														}}
														disabled={username.id.length === 0}
														onClick={handleSubmit}
														type="button ">
														<b>Login</b>
													</button>
													<p className="mt-3">
														<Link
															to="/signup"
															className="text-decoration-none ra">
															Create New User
														</Link>
														<Link
															to="/reset_password"
															className="text-decoration-none ra aaa">
															Reset Password
														</Link>{" "}
													</p>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</main>
			{/* <!-- End #main --> */}
			{/* <!-- Vendor JS Files --> */}
			<script src="../../assets/vendor/apexcharts/apexcharts.min.js"></script>
			<script src="../../assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
			<script src="../../assets/vendor/chart.js/chart.umd.js"></script>
			<script src="../../assets/vendor/echarts/echarts.min.js"></script>
			<script src="../../assets/vendor/quill/quill.min.js"></script>
			<script src="../../assets/vendor/simple-datatables/simple-datatables.js"></script>
			<script src="../../assets/vendor/tinymce/tinymce.min.js"></script>
			<script src="../../assets/vendor/php-email-form/validate.js"></script>
			{/* <!-- Template Main JS File --> */}
			<script src="../../assets/js/main.js"></script>
		</div>
	);
};

export default Login;
