import React from "react";
import BaseRoutes from "./routes/routes";

function App() {
	return (
		<>
			<BaseRoutes />
		</>
	);
}

export default App;
