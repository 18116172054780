import React from "react";
import { useDispatch, useSelector } from "react-redux";

const Alert = () => {
	const alertState = useSelector((store) => store.alert);
	const dispatch = useDispatch();
	if (alertState.alert) {
		setTimeout(() => {
			dispatch({
				type: "SHOW_ALERT",
				alert: false,
			});
		}, 5000);
	}

	return (
		<div className="d-flex align-items-center justify-content-center">
			{alertState.alert && (
				<div
					style={{
						position: "absolute",
						marginTop: "55rem",
						zIndex: "1000",
					}}>
					<div
						className={`alert alert-${alertState.alertType} alert-dismissible fade show border-0`}
						role="alert">
						<h4>{alertState.msg}</h4>

						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="alert"
							aria-label="Close"></button>
					</div>
				</div>
			)}
		</div>
	);
};

export default Alert;
