import React, { useState } from "react";
import imag from "../../assets/img/signup.jpg";
import axios from "axios";
import config from "../../config/config.json";
import validator from "../../helper/validator";
import { useNavigate } from "react-router-dom";
const host = config.APP_URL;

const Signup = (props) => {
	const navigate = useNavigate();
	const [signUp, setSignup] = useState({});
	const [sendOTP, setSendOTP] = useState(false);
	const [registered, setRegistered] = useState(false);

	const handleChange = (e) => {
		setSignup({ ...signUp, [e.target.name]: e.target.value });
	};

	const checkPassword = () => {
		const pass = document.getElementById("password").value;
		const confirmPass = document.getElementById("confirmPassword").value;
		const passAlert = document.getElementById("alert");

		passAlert.innerHTML = null;
		if (pass !== confirmPass) {
			passAlert.innerHTML = "Password Not Matched";
			passAlert.style.color = "red";
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const result = validator.validateSendOTP.validate(signUp);
		const { error, value } = result;
		if (!error) {
			await axios
				.post(
					`${host}/api/auth/sendOtp`,
					{
						mobile: value.mobile,
						email: value.email,
						country: value.country,
					},
					{
						headers: {
							"Content-Type": "application/json",
							"x-access-token": localStorage.getItem("token"),
						},
					}
				)
				.then((res) => {
					if (res.data.success === true) {
						if (res.data.token) {
							localStorage.setItem("token", res.data.token);
						}
						props.showAlert(true, res.data.message, "success");
						setSendOTP(true);
					} else {
						props.showAlert(true, res.data.error, "danger");
					}
				})
				.catch((err) => {
					props.showAlert(true, err.response.data.error, "danger");
					if (err.response.status === 401) {
						props.showAlert(true, err.response.data.error, "danger");
						localStorage.clear();
						navigate("/login");
					}
				});
		} else {
			props.showAlert(true, error.details[0].message, "danger");
		}
	};

	const handleSignUp = async (e) => {
		e.preventDefault();
		const result = validator.validateSignup.validate(signUp);
		const { error, value } = result;
		if (!error) {
			await axios
				.post(`${host}/api/auth/signup`, value, {
					headers: {
						"Content-Type": "application/json",
					},
				})

				.then((res) => {
					if (res.data.success === true) {
						if (res.data.token) {
							localStorage.setItem("token", res.data.token);
						}
						props.showAlert(true, res.data.message, "success");
						setRegistered(true);
					} else {
						props.showAlert(true, res.data.error, "danger");
					}
				})
				.catch((err) => {
					props.showAlert(true, err.response.data.error, "danger");
					if (err.response.status === 401) {
						props.showAlert(true, err.response.data.error, "danger");
						localStorage.clear();
						navigate("/login");
					}
				});
		} else {
			props.showAlert(true, error.details[0].message, "danger");
		}
	};

	return (
		<>
			<div className="bg-white">
				<div className="container">
					<div className="row">
						<div className="col signupOuter mediaq sigou">
							<div className="">
								<img className="imaga" src={imag} alt="" />
							</div>
						</div>
						<div className="col signupOuter sigou">
							<div className="card mb-3 loginBorderRadius">
								<div className="card-body">
									<div className="">
										<h5 className="card-title text-center fs-4">
											<u>Sign Up</u>
										</h5>
									</div>

									{!registered ? (
										<>
											<form className="px-2">
												<div className="row mb-2">
													<div className="col-lg-6 col-sm-12 col-md-12">
														<label
															htmlFor="exampleInputPassword1"
															className="form-label">
															Country
														</label>
														<select
															disabled={sendOTP}
															name="country"
															onChange={handleChange}
															className="form-select"
															aria-label="Default select example">
															<option value="">
																--- Select Your Country ---
															</option>
															<option value="India">India</option>
															<option value="Others">Others</option>
														</select>
													</div>
													<div className="col-lg-6 col-sm-12 col-md-12">
														<label
															htmlFor="exampleInputPassword1"
															className="form-label">
															Gender
														</label>
														<select
															disabled={sendOTP}
															name="gender"
															onChange={handleChange}
															className="form-select"
															aria-label="Default select example">
															<option value="">
																--- Select Your Gender ---
															</option>
															<option value="Male">Male</option>
															<option value="Female">Female</option>
														</select>
													</div>
												</div>
												<div className="row mb-2">
													<div className="col-lg-6 col-sm-12 col-md-12">
														<label
															htmlFor="exampleInputPassword1"
															className="form-label">
															Name
														</label>
														<input
															disabled={sendOTP}
															onChange={handleChange}
															type="text"
															name="name"
															value={signUp.name}
															className="form-control inputt"
															aria-label="Name"
															placeholder="Enter Your Name"
														/>
													</div>
													<div className="col-lg-6 col-sm-12 col-md-12">
														<label
															htmlFor="exampleInputPassword1"
															className="form-label">
															Mobile Number
														</label>
														<input
															required
															disabled={sendOTP}
															type="text"
															className="form-control inputt"
															name="mobile"
															maxLength={10}
															minLength={10}
															onChange={handleChange}
															value={signUp.mobile}
															aria-label="Mobile"
															placeholder="Enter Your Mobile Number"
														/>
													</div>
												</div>
												<div className="row mb-2">
													<div className="col">
														<label
															htmlFor="exampleInputEmail1"
															className="form-label">
															Enter Your Address
														</label>
														<input
															disabled={sendOTP}
															type="text"
															name="address"
															onChange={handleChange}
															value={signUp.address}
															className="form-control inputt"
															placeholder="Enter Your Address"
															id="exampleInputEmail1"
															aria-describedby="emailHelp"
														/>
													</div>
												</div>
												<div className="row mb-2">
													<div className="col">
														<label
															htmlFor="exampleInputPassword1"
															className="form-label">
															Email
														</label>
														<input
															required
															disabled={sendOTP}
															type="email"
															name="email"
															onChange={handleChange}
															value={signUp.email}
															className="form-control"
															aria-label="Email"
															placeholder="Enter Your Email"
														/>
													</div>
												</div>

												<div className="row">
													<div className="col-lg-6 col-sm-12 col-md-12">
														<label
															htmlFor="exampleInputPassword1"
															className="form-label">
															Password
														</label>
														<input
															required={true}
															disabled={sendOTP}
															type="password"
															onChange={handleChange}
															value={signUp.password}
															name="password"
															id="password"
															maxLength={12}
															className="form-control inputt"
															placeholder="Enter Your Password"
															aria-label="Password"
															autoComplete="none"
														/>
													</div>
													<div className="col-lg-6 col-sm-12 col-md-12">
														<label
															htmlFor="exampleInputPassword1"
															className="form-label">
															Confirm Password
														</label>
														<input
															required={true}
															disabled={sendOTP}
															onChange={handleChange}
															onKeyUp={checkPassword}
															type="password"
															maxLength={12}
															id="confirmPassword"
															name="confirmPassword"
															className="form-control"
															placeholder="Confirm Your Password"
															aria-label="Confirm Password"
															autoComplete="none"
														/>
														<span id="alert"></span>
													</div>
												</div>

												{sendOTP ? (
													<>
														<hr />
														<div className="mb-4">
															<label
																htmlFor="exampleInputEmail1"
																className="form-label">
																OTP
															</label>
															<input
																onChange={handleChange}
																value={signUp.otp}
																type="text"
																name="otp"
																maxLength={6}
																className="form-control"
																placeholder="Enter Your OTP"
																id="exampleInputEmail1"
																aria-describedby="emailHelp"
															/>
														</div>
													</>
												) : null}

												<div className="d-flex justify-content-between mt-4">
													<button
														type="button"
														onClick={() => {
															navigate("/login");
														}}
														className="btn btn-primary btncolor px-4 py-2">
														<i className="bi bi-arrow-left-circle"></i>&nbsp;
														Back
													</button>
													<button
														type="button"
														// disabled={
														// 	signUp.password !== signUp.confirmPassword
														// }
														onClick={!sendOTP ? handleSubmit : handleSignUp}
														className="btn btn-primary btncolor px-4 py-2">
														Submit
													</button>
												</div>
											</form>
										</>
									) : (
										<>
											<p>You Are Registered With Sant Gitanjali Chikitsalaya</p>
											<button
												className="btn btn-primary btncolor px-5 py-3"
												type="button"
												onClick={() => {
													navigate("/login");
												}}>
												Login
											</button>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <!-- End #main --> */}
				{/* <!-- Vendor JS Files --> */}
				<script src="../../assets/vendor/apexcharts/apexcharts.min.js"></script>
				<script src="../../assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
				<script src="../../assets/vendor/chart.js/chart.umd.js"></script>
				<script src="../../assets/vendor/echarts/echarts.min.js"></script>
				<script src="../../assets/vendor/quill/quill.min.js"></script>
				<script src="../../assets/vendor/simple-datatables/simple-datatables.js"></script>
				<script src="../../assets/vendor/tinymce/tinymce.min.js"></script>
				<script src="../../assets/vendor/php-email-form/validate.js"></script>
				{/* <!-- Template Main JS File --> */}
				<script src="../../assets/js/main.js"></script>
			</div>
		</>
	);
};

export default Signup;
