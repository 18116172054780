import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../../config/config.json";
import moment from "moment";

const host = config.APP_URL;

const UserAppointmentHistory = (props) => {
	const reqParams = useParams();
	const navigate = useNavigate();
	const [bookingData, setBookingData] = useState([]);

	const getBookingDetails = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/user/appointment?user=${reqParams.id}`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				props.showLoader(false);
				setBookingData(response.data.data);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};
	useEffect(() => {
		getBookingDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<main id="main" className="main">
				<div className="pagetitle">
					<div>
						<h1>Appointment History</h1>
						<nav>
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<Link to="/user">User</Link>
								</li>
								<li className="breadcrumb-item active">Appointment History</li>
							</ol>
						</nav>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<h5 className="card-title">Appointment History</h5>
						{bookingData.length > 0 ? (
							<div className="accordion" id="accordionPanelsStayOpenExample">
								{bookingData.map((element, index) => {
									return (
										<>
											<div className="accordion-item" key={index + 1}>
												<h2
													className="accordion-header"
													id="panelsStayOpen-headingOne">
													<button
														className="accordion-button"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target={`#collapse${index + 1}`}
														aria-expanded="true"
														aria-controls="panelsStayOpen-collapseOne">
														{`${element.referenceNumber} (${moment
															.utc(element.bookingDate)
															.format("DD MMM YYYY hh:mm:ss A")})`}
													</button>
												</h2>
												<div
													id={`collapse${index + 1}`}
													className="accordion-collapse collapse"
													aria-labelledby="headingOne">
													<div className="accordion-body">
														<div className="container">
															<div className="accordion-body">
																<div className="row">
																	<div className="col-lg-3 col-md-4 label">
																		Reference Number
																	</div>
																	<div className="col-lg-9 col-md-8 profileContent">
																		{element.referenceNumber}
																	</div>
																</div>

																<div className="row">
																	<div className="col-lg-3 col-md-4 label">
																		Appointment Date
																	</div>
																	<div className="col-lg-9 col-md-8 profileContent">
																		{moment
																			.utc(element.bookingDate)
																			.format("DD/MM/YYYY hh:mm:ss A")}
																	</div>
																</div>

																<div className="row">
																	<div className="col-lg-3 col-md-4 label">
																		Service
																	</div>
																	<div className="col-lg-9 col-md-8 profileContent">
																		{element.patientType}
																	</div>
																</div>

																<div className="row">
																	<div className="col-lg-3 col-md-4 label">
																		Appointment Status
																	</div>
																	<div className="col-lg-9 col-md-8 profileContent">
																		{element.bookingStatus}
																	</div>
																</div>

																<div className="row">
																	<div className="col-lg-3 col-md-4 label">
																		Name
																	</div>
																	<div className="col-lg-9 col-md-8 profileContent">
																		{element.patientName}
																	</div>
																</div>

																<div className="row">
																	<div className="col-lg-3 col-md-4 label">
																		Mobile
																	</div>
																	<div className="col-lg-9 col-md-8 profileContent">
																		{element.patientMobile}
																	</div>
																</div>

																<div className="row">
																	<div className="col-lg-3 col-md-4 label">
																		Gender
																	</div>
																	<div className="col-lg-9 col-md-8 profileContent">
																		{element.patientGender}
																	</div>
																</div>

																<div className="row">
																	<div className="col-lg-3 col-md-4 label">
																		Aadhaar
																	</div>
																	<div className="col-lg-9 col-md-8 profileContent">
																		{element.patientAadhar}
																	</div>
																</div>

																<div className="row">
																	<div className="col-lg-3 col-md-4 label">
																		Address
																	</div>
																	<div className="col-lg-9 col-md-8 profileContent">
																		{element.patientAddress}
																	</div>
																</div>

																<div className="row">
																	<div className="col-lg-3 col-md-4 label">
																		Booking Date
																	</div>
																	<div className="col-lg-9 col-md-8 profileContent">
																		{moment
																			.utc(element.bookedOn)
																			.format("DD-MMM-YYYY hh:mm:ss A")}
																	</div>
																</div>

																{element.patientAttachment ||
																element.patientAttachment === null ||
																undefined ? (
																	<div className="row">
																		<div className="col-lg-3 col-md-4 label">
																			Attachment
																		</div>
																		<div className="col-lg-9 col-md-8 profileContent">
																			<a
																				href={`${host}${element.patientAttachment}`}
																				target="_blank"
																				rel="noreferrer"
																				className="text-decoration-none">
																				Click Here To Download
																			</a>
																		</div>
																	</div>
																) : null}
															</div>
															<button
																className="btn btn-success"
																type="button"
																onClick={() => {
																	navigate(`/receipt/${element._id}`);
																}}>
																Print Reciept
															</button>
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</div>
						) : (
							<>
								<div
									className="col-md-12 col-lg-12 d-flex justify-content-center align-items-center"
									style={{ height: "200px" }}>
									<h4>No Data Found</h4>
								</div>
							</>
						)}
					</div>
				</div>
			</main>
		</>
	);
};

export default UserAppointmentHistory;
