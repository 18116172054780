import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const PublicRoute = ({ children }) => {
	const location = useLocation();
	const accessToken = localStorage.getItem("token") || "";
	const isUserLoggedIn = accessToken.length > 0;
	if (isUserLoggedIn) {
		return <Navigate to="/dashboard" state={{ from: location }} replace />;
	}
	return children;
};

export default PublicRoute;
