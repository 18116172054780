import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/sgc_logo.jpeg";
import doc1 from "../assets/img/doctors/Doctor1.png";
import doc2 from "../assets/img/doctors/Doctor2.jpg";
import config from "../../config/config.json";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
const host = config.APP_URL;

const Mainpage = (props) => {
	const [information, setInformation] = useState([]);
	const [openNav, setOpenNav] = useState(false);
	const fetchInformation = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/utility/notice`, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				props.showLoader(false);
				setInformation(response.data.data);
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
			});
	};

	let navClass = "";
	if (openNav === true) {
		navClass = "navbar-mobile";
	} else {
		navClass = "";
	}

	useEffect(() => {
		fetchInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{/* <!-- ======= Top Bar ======= --> */}
			<div id="topbar" className="d-flex align-items-center fixed-top">
				<div className="container d-flex justify-content-between">
					<div className="contact-info d-flex align-items-center">
						<i className="bi bi-envelope"></i>{" "}
						<Link to="mailto:info@santgeetanjalichikitsalaya.com">
							DO OFFICE REGD. - 0052 Ay/u
						</Link>
						<i className="bi bi-envelope"></i>{" "}
						<Link>C.M.O. OFF. REGD. : 216</Link>
						<i className="bi bi-phone"></i>{" "}
						<Link className="colorphone" to="tel:+919838839116">
							+91 9838839116 (सुबह 9 से 3 बजे के बीच )
						</Link>
					</div>
					<div className="d-none d-lg-flex social-links align-items-center"></div>
				</div>
			</div>

			{/* <!-- ======= Header ======= --> */}
			<header id="header" className="fixed-top">
				<div className="container d-flex align-items-center">
					<h1 className="logo me-auto">
						<Link to="/">
							<u>आयुर्वेद</u>
						</Link>
					</h1>

					<nav
						id="navbar"
						className={`navbar order-last order-lg-0 ${navClass}`}>
						<ul>
							<li>
								<HashLink
									className="nav-link scrollto"
									smooth
									to="/#home"
									onClick={() => {
										setOpenNav(false);
									}}>
									Home
								</HashLink>
							</li>
							<li>
								<HashLink
									className="nav-link scrollto"
									smooth
									to="/#services"
									onClick={() => {
										setOpenNav(false);
									}}>
									Services
								</HashLink>
							</li>
							{/* <li>
								<Link className="nav-link scrollto" to="/#departments">
									Facilities
								</Link>
							</li> */}
							<li>
								<HashLink
									className="nav-link scrollto"
									smooth
									to="/#doctors"
									onClick={() => {
										setOpenNav(false);
									}}>
									Doctors
								</HashLink>
							</li>
							<li>
								<HashLink
									className="nav-link scrollto"
									smooth
									to="/#information"
									onClick={() => {
										setOpenNav(false);
									}}>
									Official Information
								</HashLink>
							</li>

							<li>
								<HashLink
									className="nav-link scrollto"
									smooth
									to="/#about"
									onClick={() => {
										setOpenNav(false);
									}}>
									About Us
								</HashLink>
							</li>
							<li>
								<HashLink
									className="nav-link scrollto"
									smooth
									to="/#contact"
									onClick={() => {
										setOpenNav(false);
									}}>
									Contact Us
								</HashLink>
							</li>
						</ul>
						<i
							className="bi bi-list mobile-nav-toggle"
							onClick={() => {
								setOpenNav(true);
							}}></i>
					</nav>
					{/* <!-- .navbar --> */}

					<Link to="/login" className="appointment-btn scrollto">
						लॉगिन करें
					</Link>
				</div>
			</header>
			{/* <!-- End Header --> */}

			{/* <!-- ======= Hero Section ======= --> */}
			<section id="home" className="d-flex align-items-center section">
				<div className="container">
					<img src={Logo} className="ayurvedimage" alt="" />
					<h1 className="heroh1">संत गीतांजली चिकित्सालय </h1>
					<h2>
						संत गीतांजली चिकित्सालय एवं शोध संस्थान की अधिकारिक वेबसाइट पर आपका
						स्वागत है |
					</h2>
					<Link to="/login" className="btn-get-started scrollto">
						Book Appointment
					</Link>
					<Link
						to="/checkAvailability"
						className="btn-get-started scrollto ms-2 ax">
						<b>Appointment Availability</b>
					</Link>
				</div>
			</section>
			{/* <!-- End Hero --> */}

			<main id="main1">
				{/* <!-- ======= Services Section ======= --> */}
				<section id="services" className="services section">
					<div className="container">
						<div className="section-title">
							<h2>Important Treatment (विशेष चिकित्सा)</h2>
						</div>

						<div className="row">
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch">
								<div className="icon-box">
									<div className="icon">
										<i className="fas fa-heartbeat"></i>
									</div>
									<h4>
										<Link to="/">सर्वाइकल पेन</Link>
									</h4>
									{/* <p>
										Lorem ipsum dolor, sit amet consectetur adipisicing elit.
										Maiores architecto, veniam voluptate corrupti ea cumque in
										totam enim.
									</p> */}
								</div>
							</div>

							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
								<div className="icon-box">
									<div className="icon">
										<i className="fas fa-pills"></i>
									</div>
									<h4>
										<Link to="/">स्पेनलाइटिस</Link>
									</h4>
									{/* <p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Mollitia molestias, nostrum vel corrupti in amet omnis a
										deserunt.
									</p> */}
								</div>
							</div>

							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
								<div className="icon-box">
									<div className="icon">
										<i className="fas fa-hospital-user"></i>
									</div>
									<h4>
										<Link to="/">मस्कुलर एवं नर्वस एट्रोफी</Link>
									</h4>
									{/* <p>
										Lorem, ipsum dolor sit amet consectetur adipisicing elit.
										Tempore saepe ratione omnis natus dolorum ea earum, placeat
										assumenda.
									</p> */}
								</div>
							</div>

							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
								<div className="icon-box">
									<div className="icon">
										<i className="fas fa-dna"></i>
									</div>
									<h4>
										<Link to="/">पुराने उदर विकार</Link>
									</h4>
									{/* <p>
										Lorem, ipsum dolor sit amet consectetur adipisicing elit.
										Distinctio voluptatem adipisci placeat! Odio, consequuntur
										omnis. Cumque, earum maiores?
									</p> */}
								</div>
							</div>

							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
								<div className="icon-box">
									<div className="icon">
										<i className="fas fa-wheelchair"></i>
									</div>
									<h4>
										<Link to="/">सभी गुप्त रोग</Link>
									</h4>
									{/* <p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Soluta aliquam earum quia eos qui ullam veritatis vel
										architecto.
									</p> */}
								</div>
							</div>

							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
								<div className="icon-box">
									<div className="icon">
										<i className="fas fa-notes-medical"></i>
									</div>
									<h4>
										<Link to="/">शुगर रोग</Link>
									</h4>
									{/* <p>
										Lorem ipsum dolor sit, amet consectetur adipisicing elit.
										Eos inventore porro temporibus reiciendis deserunt harum
										atque sint officia.
									</p> */}
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <!-- End Services Section -->

    <!-- ======= Departments Section ======= --> */}
				{/* <section id="departments" className="departments section">
					<div className="container">
						<div className="section-title">
							<h2>Facilities (सुविधाएँ)</h2>
						</div>

						<div className="row gy-4">
							<div className="col-lg-3">
								<ul className="nav nav-tabs flex-column">
									<li className="nav-item">
										<Link
											className="nav-link active show"
											data-bs-toggle="tab"
											to="#tab-1">
											पंचकर्म
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" data-bs-toggle="tab" to="#tab-2">
											कायाकल्प
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" data-bs-toggle="tab" to="#tab-3">
											Hepatology
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" data-bs-toggle="tab" to="#tab-4">
											Pediatrics
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" data-bs-toggle="tab" to="#tab-5">
											Eye Care
										</Link>
									</li>
								</ul>
							</div>
							<div className="col-lg-9">
								<div className="tab-content">
									<div className="tab-pane active show" id="tab-1">
										<div className="row gy-4">
											<div className="col-lg-8 details order-2 order-lg-1">
												<h3>पंचकर्म</h3>
												<p className="fst-italic">
													Qui laudantium consequatur laborum sit qui ad sapiente
													dila parde sonata raqer a videna mareta paulona marka
												</p>
												<p>
													Et nobis maiores eius. Voluptatibus ut enim blanditiis
													atque harum sint. Laborum eos ipsum ipsa odit magni.
													Incidunt hic ut molestiae aut qui. Est repellat minima
													eveniet eius et quis magni nihil. Consequatur dolorem
													quaerat quos qui similique accusamus nostrum rem vero
												</p>
											</div>
											<div className="col-lg-4 text-center order-1 order-lg-2">
												<img
													src={ayurvedImage}
													alt=""
													className="img-fluid"
												/>
											</div>
										</div>
									</div>
									<div className="tab-pane" id="tab-2">
										<div className="row gy-4">
											<div className="col-lg-8 details order-2 order-lg-1">
												<h3>कायाकल्प</h3>
												<p className="fst-italic">
													Qui laudantium consequatur laborum sit qui ad sapiente
													dila parde sonata raqer a videna mareta paulona marka
												</p>
												<p>
													Ea ipsum voluptatem consequatur quis est. Illum error
													ullam omnis quia et reiciendis sunt sunt est. Non
													aliquid repellendus itaque accusamus eius et velit
													ipsa voluptates. Optio nesciunt eaque beatae accusamus
													lerode pakto madirna desera vafle de nideran pal
												</p>
											</div>
											<div className="col-lg-4 text-center order-1 order-lg-2">
												<img
													src="assets/img/departments-2.jpg"
													alt=""
													className="img-fluid"
												/>
											</div>
										</div>
									</div>
									<div className="tab-pane" id="tab-3">
										<div className="row gy-4">
											<div className="col-lg-8 details order-2 order-lg-1">
												<h3>
													Impedit facilis occaecati odio neque aperiam sit
												</h3>
												<p className="fst-italic">
													Eos voluptatibus quo. Odio similique illum id quidem
													non enim fuga. Qui natus non sunt dicta dolor et. In
													asperiores velit quaerat perferendis aut
												</p>
												<p>
													Iure officiis odit rerum. Harum sequi eum illum
													corrupti culpa veritatis quisquam. Neque
													necessitatibus illo rerum eum ut. Commodi ipsam minima
													molestiae sed laboriosam a iste odio. Earum odit
													nesciunt fugiat sit ullam. Soluta et harum voluptatem
													optio quae
												</p>
											</div>
											<div className="col-lg-4 text-center order-1 order-lg-2">
												<img
													src="assets/img/departments-3.jpg"
													alt=""
													className="img-fluid"
												/>
											</div>
										</div>
									</div>
									<div className="tab-pane" id="tab-4">
										<div className="row gy-4">
											<div className="col-lg-8 details order-2 order-lg-1">
												<h3>
													Fuga dolores inventore laboriosam ut est accusamus
													laboriosam dolore
												</h3>
												<p className="fst-italic">
													Totam aperiam accusamus. Repellat consequuntur iure
													voluptas iure porro quis delectus
												</p>
												<p>
													Eaque consequuntur consequuntur libero expedita in
													voluptas. Nostrum ipsam necessitatibus aliquam fugiat
													debitis quis velit. Eum ex maxime error in consequatur
													corporis atque. Eligendi asperiores sed qui veritatis
													aperiam quia a laborum inventore
												</p>
											</div>
											<div className="col-lg-4 text-center order-1 order-lg-2">
												<img
													src="assets/img/departments-4.jpg"
													alt=""
													className="img-fluid"
												/>
											</div>
										</div>
									</div>
									<div className="tab-pane" id="tab-5">
										<div className="row gy-4">
											<div className="col-lg-8 details order-2 order-lg-1">
												<h3>
													Est eveniet ipsam sindera pad rone matrelat sando reda
												</h3>
												<p className="fst-italic">
													Omnis blanditiis saepe eos autem qui sunt debitis
													porro quia.
												</p>
												<p>
													Exercitationem nostrum omnis. Ut reiciendis
													repudiandae minus. Omnis recusandae ut non quam ut
													quod eius qui. Ipsum quia odit vero atque qui
													quibusdam amet. Occaecati sed est sint aut vitae
													molestiae voluptate vel
												</p>
											</div>
											<div className="col-lg-4 text-center order-1 order-lg-2">
												<img
													src="assets/img/departments-5.jpg"
													alt=""
													className="img-fluid"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				{/* <!-- End Departments Section --> */}

				{/* <!-- ======= Doctors Section ======= --> */}
				<section id="doctors" className="doctors section">
					<div className="container">
						<div className="section-title">
							<h2>Doctors (डॉक्टर)</h2>
						</div>

						<div className="row">
							<div className="col-lg-6">
								<div className="col-md-12">
									<div className="member d-flex align-items-start row">
										<div className="pic col-md-5">
											<img
												src={doc1}
												style={{
													marginLeft: "-12px",
													height: "150px",
													width: "150px",
												}}
												alt=""
											/>
										</div>
										<div className="member-info col-md-7">
											<h4>डॉक्टर संत कुमार सिंह </h4>
											<span>
												भूतपूर्व प्रभारी चिकित्साधिकारी / जड़ी-बूटी शोधाधिकारी
												हिमालय क्षेत्र , उत्तराखंड सरकार
											</span>
											<p>
												रिटायर्ड प्रभारी चिकित्साधिकारी (राजकीय आयुर्वेदिक
												चिकित्सालय ) शहज़ादपुर अम्बेडकर नगर उ.प्र. सरकार{" "}
											</p>
											<div className="social">
												<Link
													to="https://twitter.com/i/flow/login"
													target="_blank">
													<i className="ri-twitter-fill"></i>
												</Link>
												<Link
													to="https://www.facebook.com/login/"
													target="_blank">
													<i className="ri-facebook-fill"></i>
												</Link>
												<Link
													to="https://www.instagram.com/accounts/login/"
													target="_blank">
													<i className="ri-instagram-fill"></i>
												</Link>
												<Link to="https://in.linkedin.com/" target="_blank">
													{" "}
													<i className="ri-linkedin-box-fill"></i>{" "}
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-lg-6 mt-4 mt-lg-0">
								<div className="member d-flex align-items-start row">
									<div className=" col-md-5 pic">
										<img
											src={doc2}
											style={{
												height: "150px",
												width: "150px",
												marginLeft: " -12px",
											}}
											alt=""
										/>
									</div>

									<div className="member-info col-md-7">
										<h4>डॉक्टर संतोष कुमार सिंह</h4>
										<span></span>
										<p> </p>
										<div className="social">
											<Link
												to="https://twitter.com/i/flow/login"
												target="_blank">
												<i className="ri-twitter-fill"></i>
											</Link>
											<Link
												to="https://www.facebook.com/login/"
												target="_blank">
												<i className="ri-facebook-fill"></i>
											</Link>
											<Link
												to="https://www.instagram.com/accounts/login/"
												target="_blank">
												<i className="ri-instagram-fill"></i>
											</Link>
											<Link to="https://in.linkedin.com/" target="_blank">
												{" "}
												<i className="ri-linkedin-box-fill"></i>{" "}
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <!-- End Doctors Section --> */}

				{/* <!-- ======= Frequently Asked Questions Section ======= --> */}
				<section id="information" className="faq section-bg section">
					<div className="container">
						<div className="section-title">
							<h2>Important Information (विशेष सूचना)</h2>
						</div>

						<div className="faq-list">
							<ul>
								{information.map((data, index) => {
									return (
										<>
											<li
												key={index}
												data-aos="fade-up"
												data-aos-delay={index * 100}>
												<a
													href="/"
													data-bs-toggle="collapse"
													className="collapsed"
													data-bs-target="#faq-list-1">
													<span>
														{index + 1}.&nbsp;{data.titleHindi}
													</span>
													<br />

													<span>
														&nbsp;&nbsp;&nbsp;&nbsp;{data.titleEnglish}
													</span>
												</a>
												{/* {index === 1 ? (
													<Link to="https://www.facebook.com" target="_blank">
														{" "}
														<b className="bi bi-download ab"></b>
													</Link>
												) : null} */}
											</li>
										</>
									);
								})}
							</ul>
						</div>
					</div>
				</section>
				{/* <!-- End Frequently Asked Questions Section --> */}

				{/* About Us Section */}
				<section id="about" className="section">
					<div className="container">
						<div className="section-title">
							<h2>About Us (हमारे बारे में)</h2>
						</div>

						<p style={{ fontSize: "16px" }}>
							Our organization, Sant Geetanjali Chikitsalaya and Shodh Sansthan,
							is an Ayurvedic Medical Center. In our Clinic, We primarily treat
							patients with diabetes, cholesterol (lipid disorders), cervical
							pain, spondylitis, muscular and nervous atrophy, chronic
							gastrointestinal disorders, thyroid disorders, skin diseases,
							high/low blood pressure, and various types of sexual disorders. We
							also provide treatment for other illnesses. Our facilities include
							Panchakarma, Naturopathy, Kayakalp, Yoga, and more. <br />
							<br />
							Our organization is the only one actively working in any capacity
							other than this. For more information, please contact Sant
							Geetanjali Chikitsalay and Research Institute located at Devkali
							Bypass, Akbarpur Road, Ayodhya (Faizabad), at 9838839116 between
							8:30 AM and 3:00 PM.
						</p>
						<br />
						<br />
						<br />
						<p style={{ fontSize: "16px" }}>
							हमारी संस्था संतगीतांजलि चिकित्सालय एवं शोध संस्थान एक आयुर्वेदिक
							पद्धति चिकित्सालय है | हमारे चिकित्सालय में मूलतः मधुमेह रोगी,
							कोलेस्ट्राल (रक्त वसा रोगी), सर्वाईकल पेन, स्पॉन्डिलाइटिस, मसकुलर
							एवं नर्वस एट्रोफी, पुराने उदर विकार,थायरोइड, चर्म रोग, उच्च/निम्न
							रक्त चाप तथा सभी प्रकार के गुप्त रोग का उपचार किया जाता है | इसके
							अलावा अन्य रोग का भी उपचार किया जाता है | सुविधा - पंचकर्म,
							नेचुरोपैथी, कायाकल्प, योगा आदि | <br />
							<br />
							हमारी कोई भी अन्य संस्था इसके अलावा किसी भी रूप में कहीं भी
							कार्यरत नहीं है | अधिक जानकारी के लिए संतगीतांजलि चिकित्सालय एवं
							शोध संस्थान देवकाली बाईपास अकबरपुर मार्ग अयोध्या (फैजाबाद)
							9838839116 से सुबह 8:30 बजे से शाम 3:00 बजे तक संपर्क करे |
						</p>
					</div>
				</section>
				{/* End About Us */}

				{/* <!-- ======= Contact Section ======= --> */}
				<section id="contact" className="contact section pb-4">
					<div className="container">
						<div className="section-title">
							<h2>Contact (संपर्क)</h2>
						</div>
					</div>

					<div>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3562.233176535203!2d82.17890100789784!3d26.768836184584984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399a08780d108749%3A0x4acf71cc2074299b!2sSant%20Gitanjali%20Ayurvedic%20Sansthan!5e0!3m2!1sen!2sin!4v1677439447716!5m2!1sen!2sin"
							width="100%"
							title="Sant Gitanjali Chikitsalaya"
							height="350px"
							style={{ border: "0" }}
							allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"></iframe>
					</div>

					<div className="container">
						<div className="row mt-5">
							<div className="col-lg-4">
								<div className="info">
									<div className="address">
										<i className="bi bi-geo-alt"></i>
										<h4>Location:</h4>
										<p>
											देवकाली बाईपास अकबरपुर रोड , औतारनगर कॉलोनी (बुधराम पुरवा
											) संघा कोल्ड स्टोर के सामने शंकरगढ़ बाजार फैज़ाबाद (उत्तर
											प्रदेश )
										</p>
									</div>
								</div>
							</div>

							<div className="col-lg-4">
								<div className="info  ">
									<div className="email mt-0">
										<i className="bi bi-envelope"></i>
										<h4>Email:</h4>
										<p>info@santgeetanjalichikitsalaya.com</p>
									</div>
								</div>
							</div>

							<div className="col-lg-4">
								<div className="info">
									<div className="phone mt-0">
										<i className="bi bi-phone"></i>
										<h4>Call:</h4>
										<p>+91 9838839116 (सुबह 9 बजे से 3 बजे तक )</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="bg-white mb-3">
					<div className="container">
						<div className="row">
							<div className="col">
								<nav
									id="navbar"
									className={`navbar order-last order-lg-0 ${navClass}`}>
									<ul>
										<li>
											<Link
												to={`${host}/api/docs/Privacy_Policy.pdf`}
												target="_blank">
												Privacy Policy
											</Link>
										</li>
										<li>
											<Link
												to={`${host}/api/docs/Terms_and_Conditions.pdf`}
												target="_blank">
												Terms & Conditions
											</Link>
										</li>
										<li>
											<Link
												to={`${host}/api/docs/Shipping_Policy.pdf`}
												target="_blank">
												Shipping Policy
											</Link>
										</li>
										<li>
											<Link
												to={`${host}/api/docs/Refund_Policy.pdf`}
												target="_blank">
												Refund Policy
											</Link>
										</li>
										<li>
											<Link
												to={`${host}/api/docs/Cancellation_Policy.pdf`}
												target="_blank">
												Cancellation Policy
											</Link>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</section>
				{/* <!-- End Contact Section --> */}
			</main>
			{/* <!-- End #main --> */}

			{/* <div id="preloader"></div> */}
			<a
				href="/"
				className="back-to-top d-flex align-items-center justify-content-center">
				<i className="bi bi-arrow-up-short"></i>
			</a>

			{/* <!-- Vendor JS Files --> */}
			<script src="assets/vendor/purecounter/purecounter_vanilla.js"></script>
			<script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
			<script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
			<script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
			<script src="assets/vendor/php-email-form/validate.js"></script>

			{/* <!-- Template Main JS File --> */}
			<script src="assets/js/main.js"></script>
		</>
	);
};

export default Mainpage;
