import React from "react";
import { Link } from "react-router-dom";

const UpdateNotice = () => {
	return (
		<>
			<main id="main" className="main">
				<div className="pagetitle">
					<div className="d-flex justify-content-between">
						<div>
							<h1>Services</h1>
							<nav>
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/dashboard">Home</Link>
									</li>
									<li className="breadcrumb-item active">Services</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default UpdateNotice;
