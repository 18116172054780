import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import config from "../../config/config.json";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { Calendar } from "primereact/calendar";
const host = config.APP_URL;

const Dashboard = (props) => {
	const navigate = useNavigate();
	const loggedInUser = localStorage.getItem("role");
	const [user, setUser] = useState({});
	const [desktopData, setDesktopData] = useState({});

	const [bookingDetails, setBookingDetails] = useState({});
	const [getTime, setTime] = useState([]);
	const [patientData, setPatientData] = useState([]);
	const [patientType, setPatientType] = useState("New");
	const [date, setDate] = useState({
		minDate: moment(),
		maxDate: moment(),
	});

	const dashboardData = async () => {
		props.showLoader(true);
		await axios
			.get(
				`${host}/api/admin/dashboard?minDate=${moment(date.minDate).format(
					"YYYY-MM-DD"
				)}&maxDate=${moment(date.maxDate).format("YYYY-MM-DD")}`,
				{
					headers: {
						"Content-Type": "application/json",
						"x-access-token": localStorage.getItem("token"),
					},
				}
			)
			.then((response) => {
				props.showLoader(false);
				setDesktopData(response.data.data);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const getPatientList = async () => {
		setPatientData([]);
		props.showLoader(true);
		await axios
			.get(
				`${host}/api/admin/patient?patientType=${patientType}&minDate=${moment(
					date.minDate
				).format("YYYY-MM-DD")}&maxDate=${moment(date.maxDate).format(
					"YYYY-MM-DD"
				)}`,
				{
					headers: {
						"Content-Type": "application/json",
						"x-access-token": localStorage.getItem("token"),
					},
				}
			)
			.then((response) => {
				props.showLoader(false);
				setPatientData(response.data.data);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const downloadExcel = () => {
		window.open(
			`${host}/api/admin/excelReport?patientType=${patientType}&minDate=${moment(
				date.minDate
			).format("YYYY-MM-DD")}&maxDate=${moment(date.maxDate).format(
				"YYYY-MM-DD"
			)}`,
			"_blank"
		);
	};

	const handleChange = (e) => {
		console.log(bookingDetails);
	};

	const handleTime = async () => {
		console.log("Booking Details--->>", bookingDetails);
		if (bookingDetails.service && bookingDetails.date) {
			props.showLoader(true);
			await axios
				.get(
					`${host}/api/utility/time?date=${moment(bookingDetails.date).format(
						"YYYY-MM-DD"
					)}&patientType=${bookingDetails.patientType}`,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((response) => {
					props.showLoader(false);
					setTime(response.data.time);
					if (response.data.token) {
						localStorage.setItem("token", response.data.token);
					}
				})
				.catch((err) => {
					props.showLoader(false);
					props.showAlert(true, err.response.data.error, "danger");
					if (err.response.status === 401) {
						localStorage.clear();
						navigate("/login");
					}
				});
		} else {
			props.showAlert(true, "Please Select Service and Booking Date", "danger");
		}
	};

	useEffect(() => {
		if (loggedInUser === "User") {
			navigate("/appointment");
		}
		dashboardData();
		setUser({ ...user, name: localStorage.getItem("name") });
		getPatientList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [patientType, date]);
	return (
		<>
			<main id="main" className="main">
				<div className="pagetitle">
					<div className="d-flex justify-content-between">
						<div>
							<h1>Dashboard</h1>
							<nav>
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link>Home</Link>
									</li>
								</ol>
							</nav>
						</div>
						<div>
							<button
								type="button"
								className="btn btn-outline-success mt-2"
								disabled={patientData.length === 0}
								onClick={downloadExcel}>
								Export Report
							</button>
						</div>
					</div>
				</div>

				<section className="dashboard">
					<div className="row">
						<div className="col-lg-12">
							<div className="row">
								<div className="col-xxl-3 col-md-6">
									<div className="card info-card sales-card">
										<div className="card-body">
											<h5 className="card-title">New Patient</h5>

											<div className="d-flex align-items-center">
												<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
													<i className="bi bi-person-plus"></i>
												</div>
												<div className="ps-3">
													<h6>{desktopData.newPatient}</h6>
													<span className="text-success small pt-1 fw-bold">
														New Patient
													</span>
													<span className="text-muted small pt-2 ps-1">
														Bookings
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xxl-3 col-md-6">
									<div className="card info-card revenue-card">
										<div className="card-body">
											<h5 className="card-title">Old Patient</h5>

											<div className="d-flex align-items-center">
												<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
													<i className="bi bi-person-check"></i>
												</div>
												<div className="ps-3">
													<h6>{desktopData.oldPatient}</h6>
													<span className="text-success small pt-1 fw-bold">
														Old Patient
													</span>
													<span className="text-muted small pt-2 ps-1">
														Bookings
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xxl-3 col-md-6">
									<div className="card info-card customers-card">
										<div className="card-body">
											<h5 className="card-title">Setting</h5>

											<div className="d-flex align-items-center">
												<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
													<i className="bi bi-person"></i>
												</div>
												<div className="ps-3">
													<h6>{desktopData.settingPatient}</h6>
													<span className="text-danger small pt-1 fw-bold">
														Setting
													</span>
													<span className="text-muted small pt-2 ps-1">
														Bookings
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xxl-3 col-md-6">
									<div className="card info-card patient-card">
										<div className="card-body">
											<h5 className="card-title">Total Patient</h5>
											<div className="d-flex align-items-center">
												<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
													<i className="bi bi-people"></i>
												</div>
												<div className="ps-3">
													<h6>{desktopData.totalPatient}</h6>
													<span className="text-danger small pt-1 fw-bold">
														Total
													</span>
													<span className="text-muted small pt-2 ps-1">
														Bookings
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card">
								<div className="card-body">
									<div className="d-flex justify-content-between">
										<h5 className="card-title">Patient List</h5>

										<div className="col-md-2 d-flex justify-content-center mt-3 w-25">
											<br />
											<Calendar
												style={{ height: "38px", padding: "0px" }}
												value={moment().format("MM/DD/YYYY")}
												placeholder="From"
												dateFormat="dd/mm/yy"
												required
												onChange={(e) => {
													setDate({ ...date, minDate: e.value });
												}}
												className="form-control"
											/>
											<Calendar
												style={{ height: "38px", padding: "0px" }}
												value={moment().format("MM/DD/YYYY")}
												placeholder="To"
												dateFormat="dd/mm/yy"
												required
												onChange={(e) => {
													setDate({ ...date, maxDate: e.value });
												}}
												className="form-control mx-2"
											/>
										</div>
									</div>
									<ul
										className="nav nav-tabs nav-tabs-bordered"
										id="borderedTab"
										role="tablist">
										<li className="nav-item" role="presentation">
											<button
												className="nav-link active"
												id="home-tab"
												data-bs-toggle="tab"
												data-bs-target="#newPatientTab"
												type="button"
												role="tab"
												onClick={() => {
													setPatientType("New");
												}}
												aria-controls="home"
												aria-selected="true">
												New Patient
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className="nav-link"
												id="profile-tab"
												data-bs-toggle="tab"
												data-bs-target="#oldPatientTab"
												type="button"
												role="tab"
												onClick={() => {
													setPatientType("Old");
												}}
												aria-controls="profile"
												aria-selected="false">
												Old Patient
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className="nav-link"
												id="contact-tab"
												data-bs-toggle="tab"
												data-bs-target="#settingPatientTab"
												type="button"
												role="tab"
												onClick={() => {
													setPatientType("Setting");
												}}
												aria-controls="contact"
												aria-selected="false">
												Setting
											</button>
										</li>
									</ul>
									<div
										className="tab-content pt-2"
										id="borderedTabContent"
										style={{ minHeight: "280px" }}>
										<div
											className="tab-pane fade show active"
											id="newPatientTab"
											role="tabpanel"
											aria-labelledby="home-tab">
											<div className="table-responsive">
												{patientData.length > 0 ? (
													<table className="table table-hover">
														<thead>
															<tr>
																<th scope="col">Ref No.</th>
																<th scope="col">Booking Date</th>
																<th scope="col">Name</th>
																<th scope="col">Mobile</th>
																<th scope="col">Type</th>
																<th scope="col">Gender</th>
																<th scope="col">Address</th>
																<th scope="col">View</th>
															</tr>
														</thead>
														<tbody>
															{patientData.map((element, index) => {
																return (
																	<tr key={index}>
																		<th scope="col">
																			{element.referenceNumber}
																		</th>
																		<td>
																			{moment
																				.utc(element.bookingDate)
																				.format("DD/MM/YYYY hh:mm:ss A")}
																		</td>
																		<td>{element.patientName}</td>
																		<td>{element.patientMobile}</td>
																		<td>{element.patientType}</td>
																		<td>{element.patientGender}</td>
																		<td>{`${element.patientAddress}, ${element.city}`}</td>

																		<td>
																			<button
																				type="button"
																				className="btn btn-primary"
																				onClick={() => {
																					setBookingDetails(element);
																				}}
																				data-bs-toggle="modal"
																				data-bs-target="#exampleModal">
																				<i
																					className="fa fa-eye"
																					aria-hidden="true"></i>
																			</button>
																		</td>
																	</tr>
																);
															})}
														</tbody>
													</table>
												) : (
													<div
														className="col-md-12 col-lg-12 d-flex justify-content-center align-items-center"
														style={{ height: "200px" }}>
														<h4>No Data Found</h4>
													</div>
												)}
											</div>
										</div>
										<div
											className="tab-pane fade"
											id="oldPatientTab"
											role="tabpanel"
											aria-labelledby="profile-tab">
											<div className="table-responsive">
												{patientData.length > 0 ? (
													<table className="table table-hover">
														<thead>
															<tr>
																<th scope="col">Ref No.</th>
																<th scope="col">Booking Date</th>
																<th scope="col">Name</th>
																<th scope="col">Mobile</th>
																<th scope="col">Type</th>
																<th scope="col">Gender</th>
																<th scope="col">Address</th>
																<th scope="col">View</th>
															</tr>
														</thead>
														<tbody>
															{patientData.map((element, index) => {
																return (
																	<tr key={index}>
																		<th scope="col">
																			{element.referenceNumber}
																		</th>
																		<td>
																			{moment
																				.utc(element.bookingDate)
																				.format("DD/MM/YYYY hh:mm:ss A")}
																		</td>
																		<td>{element.patientName}</td>
																		<td>{element.patientMobile}</td>
																		<td>{element.patientType}</td>
																		<td>{element.patientGender}</td>
																		<td>{`${element.patientAddress}, ${element.city}`}</td>
																		<td>
																			<button
																				type="button"
																				className="btn btn-primary"
																				data-bs-toggle="modal"
																				onClick={() => {
																					setBookingDetails(element);
																				}}
																				data-bs-target="#exampleModal">
																				<i
																					className="fa fa-eye"
																					aria-hidden="true"></i>
																			</button>
																		</td>
																	</tr>
																);
															})}
														</tbody>
													</table>
												) : (
													<div
														className="col-md-12 col-lg-12 d-flex justify-content-center align-items-center"
														style={{ height: "200px" }}>
														<h4>No Data Found</h4>
													</div>
												)}
											</div>
										</div>
										<div
											className="tab-pane fade"
											id="settingPatientTab"
											role="tabpanel"
											aria-labelledby="contact-tab">
											<div className="table-responsive">
												{patientData.length > 0 ? (
													<table className="table table-hover">
														<thead>
															<tr>
																<th scope="col">Ref No.</th>
																<th scope="col">Booking Date</th>
																<th scope="col">Name</th>
																<th scope="col">Mobile</th>
																<th scope="col">Type</th>
																<th scope="col">Gender</th>
																<th scope="col">Address</th>
																<th scope="col">View</th>
															</tr>
														</thead>
														<tbody>
															{patientData.map((element, index) => {
																return (
																	<tr key={index}>
																		<th scope="col">
																			{element.referenceNumber}
																		</th>
																		<td>
																			{moment
																				.utc(element.bookingDate)
																				.format("DD/MM/YYYY hh:mm:ss A")}
																		</td>
																		<td>{element.patientName}</td>
																		<td>{element.patientMobile}</td>
																		<td>{element.patientType}</td>
																		<td>{element.patientGender}</td>
																		<td>{`${element.patientAddress}, ${element.city}`}</td>
																		<td>
																			<button
																				type="button"
																				className="btn btn-primary"
																				data-bs-toggle="modal"
																				onClick={() => {
																					setBookingDetails(element);
																				}}
																				data-bs-target="#exampleModal">
																				<i
																					className="fa fa-eye"
																					aria-hidden="true"></i>
																			</button>
																		</td>
																	</tr>
																);
															})}
														</tbody>
													</table>
												) : (
													<div
														className="col-md-12 col-lg-12 d-flex justify-content-center align-items-center"
														style={{ height: "200px" }}>
														<h4>No Data Found</h4>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* ----------------modal ------------------------------------------------ */}

							<div
								className="modal fade"
								// id="exampleModal"
								tabIndex="-1"
								aria-labelledby="exampleModalLabel"
								aria-hidden="true">
								<div className="modal-dialog modal-dialog-centered modal-lg">
									<div className="modal-content">
										<div className="modal-header">
											<h1 className="modal-title fs-5" id="exampleModalLabel">
												Update Appointment
											</h1>
											<button
												type="button"
												className="btn-close"
												data-bs-dismiss="modal"
												aria-label="Close"></button>
										</div>
										<div className="modal-body">
											<form className="row g-3">
												<div className="col-md-4">
													<label htmlFor="inputState" className="form-label">
														Time
													</label>
													<select
														className="form-select"
														onChange={handleChange}
														onFocus={handleTime}
														required
														name="time">
														<option value={null}>Select Time</option>

														{getTime.map((element, index) => {
															return (
																<option
																	value={element}
																	key={index}
																	className="">
																	{element}
																</option>
															);
														})}
													</select>
												</div>
												<div className="col-md-4">
													<label htmlFor="inputState" className="form-label">
														Date
													</label>
													<Calendar
														style={{ height: "35px", padding: "0px" }}
														disabledDays="[4,5]"
														value={bookingDetails.date}
														placeholder="Booking Date"
														dateFormat="dd/mm/yy"
														required
														onChange={(e) => {
															setBookingDetails({
																...bookingDetails,
																date: e.value,
															});
														}}
														className="form-control"
														minDate={new Date()}
													/>
												</div>
											</form>
											{/* <div className="container">
												<div className="row">
													<div className="col-md-4">
														<label htmlFor="inputState" className="form-label">
															Time
														</label>
														<select
															className="form-select"
															onChange={handleChange}
															onFocus={handleTime}
															required
															name="time">
															<option value={null}>Select Time</option>

															{getTime.map((element, index) => {
																return (
																	<option
																		value={element}
																		key={index}
																		className="">
																		{element}
																	</option>
																);
															})}
														</select>
													</div>
												</div>
												<br />

												<div className="row">
													<div className="col-md-6">
														<input
															type="text"
															className="form-control"
															value={bookingDetails.patientName}
														/>
													</div>

													<div className="col-md-6">
														<input
															type="text"
															className="form-control"
															value={bookingDetails.patientType}
														/>
													</div>
												</div>
												<br />
											</div> */}
										</div>
										<div className="modal-footer">
											<button
												type="button"
												className="btn btn-secondary"
												data-bs-dismiss="modal">
												Close
											</button>
											<button type="button" className="btn btn-primary">
												Save changes
											</button>
										</div>
									</div>
								</div>
							</div>

							{/* ------------------------end modal---------------------------------------- */}
						</div>
					</div>
				</section>
			</main>
		</>
	);
};

export default Dashboard;
