import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import config from "../../config/config.json";
import { Calendar } from "primereact/calendar";
import axios from "axios";
const host = config.APP_URL;

const Appointment = (props) => {
	const navigate = useNavigate();
	const [bookingDetails, setBookingDetails] = useState({});
	const [service, setService] = useState([]);
	const [countryCodes, setCountryCodes] = useState([]);
	const [getTime, setTime] = useState([]);
	const [file, setFile] = useState(null);
	const [orderData, setOrderData] = useState({
		orderNumber: null,
		merchantId: null,
		amount: null,
	});
	const [agree, setAgree] = useState(false);

	const d = new Date();
	d.setDate(d.getDate() + 1);

	const handleChange = (e) => {
		setBookingDetails({ ...bookingDetails, [e.target.name]: e.target.value });
		setFile({ ...file, [e.target.name]: e.target.files[0] });
	};
	const getService = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/admin/service`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				props.showLoader(false);
				setService(response.data.data);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const getCountryCodes = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/utility/countryCode`, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				props.showLoader(false);
				setCountryCodes(response.data.data);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	const handleTime = async () => {
		if (bookingDetails.service && bookingDetails.date) {
			props.showLoader(true);
			await axios
				.get(
					`${host}/api/utility/time?date=${moment(bookingDetails.date).format(
						"YYYY-MM-DD"
					)}&patientType=${bookingDetails.service}`,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((response) => {
					props.showLoader(false);
					setTime(response.data.time);
					setOrderData(response.data.orderData);
					if (response.data.token) {
						localStorage.setItem("token", response.data.token);
					}
				})
				.catch((err) => {
					props.showLoader(false);
					props.showAlert(true, err.response.data.error, "danger");
					if (err.response.status === 401) {
						localStorage.clear();
						navigate("/login");
					}
				});
		} else {
			props.showAlert(true, "Please Select Service and Booking Date", "danger");
		}
	};

	useEffect(() => {
		getService();
		getCountryCodes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<main id="main" className="main">
				<div className="pagetitle">
					<h1>Appointment</h1>
					<nav>
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link>Home</Link>
							</li>
							<li className="breadcrumb-item active">Appointment</li>
						</ol>
					</nav>
				</div>
				<div className="card">
					<div className="card-body">
						<div className="d-flex justify-content-between">
							<h5 className="card-title">Book Your Appointment</h5>
							<div className="mt-3">
								<ul className="list-group list-group-horizontal">
									{service.map((element) => {
										return (
											<>
												<li className="list-group-item">
													{element.title} Patient : Rs.{element.price}/-
												</li>
											</>
										);
									})}
								</ul>
							</div>
						</div>
						<form
							className="row g-3"
							method="POST"
							action={`${host}/api/user/appointment`}>
							<input
								hidden
								type="text"
								name="merchant_id"
								id="merchant_id"
								value={orderData.merchantId}
							/>
							<input
								hidden
								type="text"
								name="order_id"
								value={orderData.orderNumber}
							/>
							<input hidden type="text" name="currency" value="INR" />
							<input
								hidden
								type="text"
								name="merchant_param1"
								value={moment(bookingDetails.date).format("YYYY-MM-DD")}
							/>
							<input
								hidden
								type="text"
								name="merchant_param2"
								value={bookingDetails.time}
							/>
							<input
								hidden
								type="text"
								name="merchant_param3"
								value={bookingDetails.aadhaar}
							/>
							<input
								hidden
								type="text"
								name="merchant_param4"
								value={bookingDetails.gender}
							/>
							<input
								hidden
								type="text"
								name="merchant_param5"
								value={bookingDetails.service}
							/>

							<input
								hidden
								type="text"
								name="redirect_url"
								value={`${host}/api/user/verifyPayment/${localStorage.getItem(
									"userId"
								)}`}
							/>
							<input
								hidden
								type="text"
								name="cancel_url"
								value={`${host}/api/user/verifyPayment/${localStorage.getItem(
									"userId"
								)}`}
							/>
							<input
								hidden
								type="text"
								value={moment(bookingDetails.date).format("YYYY-MM-DD")}
								name="booking_date"
							/>
							<input
								hidden
								type="text"
								name="amount"
								value={orderData.amount}
							/>
							<input
								hidden
								type="text"
								name="language"
								id="language"
								value="EN"
							/>
							<div className="col-md-4">
								<label htmlFor="inputState" className="form-label">
									Service
								</label>
								<select
									className="form-select"
									onChange={handleChange}
									required={true}
									name="service">
									<option value={null}>Select Service</option>
									{service.map((element, index) => {
										return (
											<option value={element.title} key={index}>
												{element.title} Patient
											</option>
										);
									})}
								</select>
							</div>
							<div className="col-md-4">
								<label htmlFor="inputEmail5" className="form-label">
									Date
								</label>
								<br />
								<Calendar
									style={{ height: "35px", padding: "0px" }}
									disabledDays="[4,5]"
									value={bookingDetails.date}
									placeholder="Booking Date"
									dateFormat="dd/mm/yy"
									required
									onChange={(e) => {
										setBookingDetails({
											...bookingDetails,
											date: e.value,
										});
									}}
									className="form-control"
									minDate={d}
								/>
							</div>
							<div className="col-md-4">
								<label htmlFor="inputState" className="form-label">
									Time
								</label>
								<select
									className="form-select"
									onChange={handleChange}
									onFocus={handleTime}
									required
									name="time">
									<option value={null}>Select Time</option>

									{getTime.map((element, index) => {
										return (
											<option value={element} key={index} className="">
												{element}
											</option>
										);
									})}
								</select>
							</div>
							<div className="col-md-6">
								<label htmlFor="inputCity" className="form-label">
									Patient Name
								</label>
								<input
									type="text"
									required
									className="form-control"
									name="billing_name"
									onChange={handleChange}
									placeholder="Patient Name"
								/>
							</div>
							<div className="col-md-6">
								<label htmlFor="inputZip" className="form-label">
									Gender
								</label>
								<select
									required
									className="form-select"
									onChange={handleChange}
									name="gender">
									<option value={null}>Select Gender</option>
									<option value="Male">Male</option>
									<option value="Female">Female</option>
								</select>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12">
								<label htmlFor="yourUsername" className="form-label">
									Select Your Country
								</label>
								<div className="input-group has-validation">
									<span className="input-group-text" id="inputGroupPrepend">
										<i className="bi bi-globe2"></i>
									</span>
									<select
										className="form-select"
										onChange={handleChange}
										name="billing_country">
										<option value={null}>-- Select Your Country --</option>
										{countryCodes.map((element, index) => {
											return (
												<option value={element.country_name} key={index}>
													{element.country_name} ({element.country_code})
												</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12">
								<label htmlFor="mobile" className="form-label">
									Mobile No.
								</label>
								<input
									required
									type="text"
									name="billing_tel"
									maxLength={10}
									className="form-control"
									placeholder="Patient Mobile No."
									onChange={handleChange}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12">
								<label htmlFor="email" className="form-label">
									Email ID
								</label>
								<input
									required
									type="email"
									name="billing_email"
									maxLength={50}
									className="form-control"
									placeholder="Patient Email ID"
									onChange={handleChange}
								/>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-12">
								<label htmlFor="address" className="form-label">
									City
								</label>
								<input
									type="text"
									name="billing_address"
									className="form-control"
									placeholder="City"
									onChange={handleChange}
								/>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-12">
								<label htmlFor="zipcode" className="form-label">
									Block / Locality
								</label>
								<input
									type="text"
									name="billing_city"
									className="form-control"
									placeholder="Block / Locality"
									onChange={handleChange}
								/>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-12">
								<label htmlFor="zipcode" className="form-label">
									State
								</label>
								<input
									type="text"
									name="billing_state"
									className="form-control"
									placeholder="State"
									onChange={handleChange}
								/>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-12">
								<label htmlFor="zipcode" className="form-label">
									Pincode
								</label>
								<input
									type="text"
									maxLength={6}
									name="billing_zip"
									className="form-control"
									placeholder="Pin Code"
									onChange={handleChange}
								/>
							</div>
							<div className="col-md-6">
								<label htmlFor="aadhaar" className="form-label">
									Aadhar Number
								</label>
								<input
									type="text"
									name="aadhaar"
									maxLength={12}
									className="form-control"
									onChange={handleChange}
									placeholder="Aadhar Number"
								/>
							</div>
							<div className="col-md-6">
								<label htmlFor="aadhaar" className="form-label">
									Amount
								</label>
								<input
									type="text"
									disabled={true}
									className="form-control"
									value={orderData.amount}
									placeholder="Amount"
								/>
							</div>
							<div className="container mt-1">
								<h5 className="card-title">Terms & Conditions</h5>
								<ol>
									<li>
										नए मरीज जाँच कराने हेतु सुबह 7 बजे से 10:30 बजे के मध्य 1
										घंटा पूर्व आएं नये मरीज की जाँच अस्पताल में ही की जाएगी
										,शुगर के रोगी आने के कम से कम 12hrs. (घंटे) पहले से शुगर की
										दवा न खा के आएं और न ही इन्सुलिन या सुई लगाना है |
									</li>

									<li>
										केवल दवा लेने हेतु सुबह 9 बजे से 3 बजे के बीच आएं; इसके लिए
										किसी भी प्रकार की बुकिंग नहीं करनी है |
									</li>
									<li>
										सभी पुराने मरीज और सेटिंग के मरीज को अपना पर्चा लाना
										अनिवार्य है, बिना पर्चे के न तो देखा जाएगा और ना ही दवा दी
										जाएगी |
									</li>

									<li>
										फीस जमा हो जाने पर और नियत समय पर न आने पर फीस की वापसी नहीं
										होगी तथा दुबारा दिखाने हेतु पुनः बुकिंग करनी होगी कृपया
										ध्यान रखे की जिस दिन आना है उसी दिन बुकिंग न करे, आने के कम
										से कम दो दिन पहले बुकिंग करे |
									</li>
									<li>
										कृपया अपने बुकिंग समय पर पहुँचना सुनिश्चित करे, अधिक देर से
										आने पर कोई भी कारण अथवा बहाना मान्य नहीं होगा तथा बुकिंग
										निरस्त कर दी जाएगी|
									</li>
								</ol>
							</div>
							<div className="container">
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										onChange={() => {
											setAgree(agree ? false : true);
										}}
										id="flexCheckDefault"
									/>
									<label
										className="form-check-label"
										htmlFor="flexCheckDefault">
										I agree Terms & Conditions
									</label>
								</div>
							</div>
							<div className="text-center">
								<button
									type="submit"
									className="btn btn-outline-success m-1"
									disabled={!agree}>
									Pay Now
								</button>
								<button type="reset" className="btn btn-outline-danger m-1">
									Reset
								</button>
							</div>
						</form>
					</div>
				</div>
			</main>
		</>
	);
};

export default Appointment;
