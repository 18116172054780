import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/sgc_logo.jpeg";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config/config.json";
import moment from "moment";
const host = config.APP_URL;

const Receipt = (props) => {
	const [receipt, setReceipt] = useState({});
	const reqParams = useParams();
	const navigate = useNavigate();
	const [print, setPrint] = useState(true);
	const handleClick = () => {
		setPrint(false);
	};

	const getReceiptData = async () => {
		props.showLoader(true);
		await axios
			.get(`${host}/api/user/receipt/${reqParams.id}`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": localStorage.getItem("token"),
				},
			})
			.then((response) => {
				props.showLoader(false);
				if (response.data.token) {
					localStorage.setItem("token", response.data.token);
				}
				setReceipt(response.data.data);
			})
			.catch((err) => {
				props.showLoader(false);
				props.showAlert(true, err.response.data.error, "danger");
				if (err.response.status === 401) {
					localStorage.clear();
					navigate("/login");
				}
			});
	};

	useEffect(() => {
		getReceiptData();
		if (!print) {
			window.print();
		}
		setPrint(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [print]);

	return (
		<>
			<div className="container w-100 mt-2">
				{print ? (
					<div className="d-flex justify-content-between">
						<button
							className="btn btn-danger"
							onClick={() => {
								navigate("/appointment");
							}}>
							<span className="bi bi-arrow-left-circle"></span> Back
						</button>
						<button className="btn btn-success" onClick={handleClick}>
							<span className="bi bi-printer"></span> Print
						</button>
					</div>
				) : null}
				<h3 style={{ textAlign: "center", fontFamily: "Times New Roman" }}>
					Print Your Appointment
				</h3>
				<table
					className="table"
					align="center"
					border={1}
					style={{ textAlign: "center" }}>
					<thead>
						<tr>
							<th>
								<div className="d-flex align-items-center justify-content-center">
									<img
										src={Logo}
										style={{
											height: "100px",
											width: "100px",
											borderRadius: "50%",
										}}
										className="img-fluid"
										alt="Sant Gitanjali Chikitsalaya"
									/>
								</div>
							</th>
							<th scope="col" colSpan={3}>
								<h1
									style={{
										fontFamily: "Times New Roman",
										textAlign: "center",
									}}>
									Sant Geetanjali Chikitsalaya
								</h1>
								<p
									style={{
										fontFamily: "Times New Roman",
										textAlign: "center",
									}}>
									Autar Nagar Colony, Budhram Ka Purwa, Faizabad 224001
									<br></br>
									+919838839116
								</p>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th scope="row">Reference Number</th>
							<th>{receipt.referenceNumber}</th>
							<th>Patient Type</th>
							<td>{receipt.patientType}</td>
						</tr>
						<tr>
							<th scope="row">Name</th>
							<td>{receipt.patientName}</td>
							<th>Gender</th>
							<td>{receipt.patientGender}</td>
						</tr>
						<tr>
							<th scope="row">Mobile Number</th>
							<td>{receipt.patientMobile}</td>
							<th>Address</th>
							<td>{`${receipt.patientAddress}, ${receipt.city}`}</td>
						</tr>
						<tr>
							<th scope="row">Appointment Time</th>
							<td>
								{moment
									.utc(receipt.bookingDate)
									.format("DD/MM/YYYY hh:mm:ss A")}
							</td>
							<th>Booked On</th>
							<td>
								{moment(receipt.bookedOn).format("DD-MM-YYYY hh:mm:ss A")}
							</td>
						</tr>
						<tr>
							<th scope="row" colSpan={2}>
								Fees
							</th>
							<td colSpan={2}>{receipt.bookingAmount} Rs.</td>
						</tr>
					</tbody>
				</table>
				<p style={{ textAlign: "center" }}>
					This is System Generated Receipt From
					https://santgitanjalichikitsalaya.com, Do Not Require Signature
				</p>
				<div className="container mt-1">
					<h5 className="card-title">Terms & Conditions</h5>
					<ol>
						<li>
							नए मरीज जाँच कराने हेतु सुबह 7 बजे से 10:30 बजे के मध्य 1 घंटा
							पूर्व आएं नये मरीज की जाँच अस्पताल में ही की जाएगी ,शुगर के रोगी
							आने के कम से कम 12hrs. (घंटे) पहले से शुगर की दवा न खा के आएं और न
							ही इन्सुलिन या सुई लगाना है |
						</li>

						<li>
							केवल दवा लेने हेतु सुबह 9 बजे से 3 बजे के बीच आएं; इसके लिए किसी
							भी प्रकार की बुकिंग नहीं करनी है |
						</li>
						<li>
							सभी पुराने मरीज और सेटिंग के मरीज को अपना पर्चा लाना अनिवार्य है,
							बिना पर्चे के न तो देखा जाएगा और ना ही दवा दी जाएगी |
						</li>

						<li>
							फीस जमा हो जाने पर और नियत समय पर न आने पर फीस की वापसी नहीं होगी
							तथा दुबारा दिखाने हेतु पुनः बुकिंग करनी होगी कृपया ध्यान रखे की
							जिस दिन आना है उसी दिन बुकिंग न करे, आने के कम से कम दो दिन पहले
							बुकिंग करे |
						</li>
						<li>
							कृपया अपने बुकिंग समय पर पहुँचना सुनिश्चित करे, अधिक देर से आने पर
							कोई भी कारण अथवा बहाना मान्य नहीं होगा तथा बुकिंग निरस्त कर दी
							जाएगी|
						</li>
					</ol>
				</div>
			</div>
		</>
	);
};

export default Receipt;
