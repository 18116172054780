import React, { useState } from "react";
import imaga from "../../assets/img/reset_password.gif";
import { useNavigate } from "react-router";
import axios from "axios";
import config from "../../config/config.json";
import validator from "../../helper/validator";
const host = config.APP_URL;

const Signup = (props) => {
	const navigate = useNavigate();
	const [data, setData] = useState({
		country: "",
		mobile: "",
		email: "",
		otp: "",
		password: "",
		confirmPass: "",
	});
	const [sentOtp, setSendOTP] = useState(false);

	const handleChange = async (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const result = validator.validateResetOtp.validate({
			country: data.country,
			mobile: data.mobile,
			email: data.email,
		});
		const { error, value } = result;
		if (!error) {
			await axios
				.post(`${host}/api/auth/resetOtp`, value, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.data.success === true) {
						if (res.data.token) {
							localStorage.setItem("token", res.data.token);
						}
						props.showAlert(true, res.data.message, "success");
						setSendOTP(true);
					} else {
						props.showAlert(true, res.data.error, "danger");
					}
				})
				.catch((err) => {
					props.showAlert(true, err.response.data.error, "danger");
					if (err.response.status === 401) {
						props.showAlert(true, err.response.data.error, "danger");
						localStorage.clear();
						navigate("/login");
					}
				});
		} else {
			props.showAlert(true, error.details[0].message, "danger");
		}
	};

	const handleOtp = async (e) => {
		e.preventDefault();
		const result = validator.validateResetPassword.validate({
			otp: data.otp,
			mobile: data.mobile,
			email: data.email,
			password: data.password,
		});
		const { error, value } = result;
		if (!error) {
			await axios
				.post(`${host}/api/auth/resetPassword`, value, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.data.success === true) {
						if (res.data.token) {
							localStorage.setItem("token", res.data.token);
						}
						props.showAlert(true, res.data.message, "success");
						setSendOTP(false);
						navigate("/login");
					} else {
						props.showAlert(true, res.data.error, "danger");
					}
				})
				.catch((err) => {
					props.showAlert(true, err.response.data.error, "danger");
					if (err.response.status === 401) {
						props.showAlert(true, err.response.data.error, "danger");
						localStorage.clear();
						navigate("/login");
					}
				});
		} else {
			props.showAlert(true, error.details[0].message, "danger");
		}
	};

	return (
		<>
			<div className="bg-white">
				<div className="container">
					<div className="row">
						<div className="col signupOuter mediaq">
							<div className="imagesss">
								<img className="imaga" src={imaga} alt="" />
							</div>
						</div>
						<div className="col signupOuter">
							<div className="card mb-3 loginBorderRadius">
								<div className="card-body mb-4 med">
									<div className="pb-2">
										<h5 className="card-title text-center pb-0 fs-4">
											<u>Reset Password</u>
										</h5>
									</div>
									<form>
										{!sentOtp ? (
											<>
												<div className="row mb-3 mx-2">
													<label
														htmlFor="exampleInputPassword1"
														className="form-label">
														Country
													</label>
													<select
														// disabled={sendOTP}
														name="country"
														onChange={handleChange}
														className="form-select"
														aria-label="Default select example">
														<option value="">
															--- Select Your Country ---
														</option>
														<option value="India">India</option>
														<option value="Others">Others</option>
													</select>
												</div>
												<div className="mb-3">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label">
														Mobile
													</label>
													<input
														onChange={handleChange}
														placeholder="Enter Your Mobile"
														name="mobile"
														type="text"
														max={10}
														className="form-control"
														value={data.mobile}
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
													/>
												</div>
												<div className="mb-3">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label">
														Email
													</label>
													<input
														onChange={handleChange}
														placeholder="Enter Your Email"
														name="email"
														type="email"
														max={50}
														className="form-control"
														value={data.email}
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
													/>
												</div>
											</>
										) : (
											<>
												<div className="mb-3">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label">
														OTP
													</label>
													<input
														placeholder="OTP"
														value={data.otp}
														onChange={handleChange}
														max={6}
														name="otp"
														type="text"
														className="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
													/>
												</div>
												<div className="mb-3">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label">
														Create Password
													</label>
													<input
														name="password"
														onChange={handleChange}
														value={data.password}
														type="password"
														placeholder="Create New Password"
														maxLength={12}
														className="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
													/>
												</div>

												<div className="mb-3">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label">
														Confirm Password
													</label>
													<input
														name="confirmPass"
														onChange={handleChange}
														value={data.confirmPass}
														placeholder="Confirm New Password"
														type="password"
														maxLength={12}
														className="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
													/>
												</div>
											</>
										)}

										{/* {sentOtp ? <></> : null} */}

										<div className="d-flex justify-content-between mt-4">
											<button
												type="submit"
												onClick={() => {
													navigate("/login");
												}}
												className="btn btn-primary btncolor px-3">
												<i className="bi bi-arrow-left-circle"></i>&nbsp; Back
											</button>
											<button
												type="button"
												onClick={!sentOtp ? handleSubmit : handleOtp}
												className="btn btn-primary btncolor px-2">
												Reset Password
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- End #main --> */}
				{/* <!-- Vendor JS Files --> */}
				<script src="../../assets/vendor/apexcharts/apexcharts.min.js"></script>
				<script src="../../assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
				<script src="../../assets/vendor/chart.js/chart.umd.js"></script>
				<script src="../../assets/vendor/echarts/echarts.min.js"></script>
				<script src="../../assets/vendor/quill/quill.min.js"></script>
				<script src="../../assets/vendor/simple-datatables/simple-datatables.js"></script>
				<script src="../../assets/vendor/tinymce/tinymce.min.js"></script>
				<script src="../../assets/vendor/php-email-form/validate.js"></script>
				{/* <!-- Template Main JS File --> */}
				<script src="../../assets/js/main.js"></script>
			</div>
		</>
	);
};

export default Signup;
