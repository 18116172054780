import * as actionTypes from "../actions";

export const initialState = {
	loader: false,
	msg: "Loading",
};

const loaderReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SHOW_LOADER:
			return {
				...state,
				loader: action.loaderBar,
				msg: action.message,
			};
		default:
			return state;
	}
};

export default loaderReducer;
